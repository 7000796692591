const $ = window.$;

const initialState = {
  estimator: false,
  estimatedTable: null,
  estimatedOnly: false,
  stateList: [],
  phoneTypeList: [],
  contractorTypeList: [],
  constants: [],
  widgetEstimateValues: {},
  ableToConvertFromWidgetToEstimate: false,
  tabs: {
    activeItem: 'Estimate Calculators',
    activeItemChildren: 'GPMD',
  },
  tabList: [
    {
      name: 'Estimate Calculators',
      children: [
        { name: 'GPMD' },
        { name: 'Markup %' },
        { name: 'Unit Price' },
        { name: 'Subs Only' },
      ],
    },
    { name: 'Company Info' },
    { name: 'Gross Income' },
    {
      name: 'COGS',
      children: [],
    },
    {
      name: 'Overhead',
      children: [
        { name: 'Summary' },
        { name: 'Admin Persons' },
        { name: 'Insurance' },
        { name: 'Marketing' },
        { name: 'Small Tools Equipment' },
        { name: 'Trucks & Equipment' },
        { name: 'General Office' },
      ],
    },
    { name: 'P & L' },
  ],
  jobTypeList: [],
  contractorType: false,
  workTypeList: [],
  companyInfoStatus: true,
  gpmdEstimator: {
    customerId: 0,
    jobId: 0,
    workOrderId: 0,
    laborRate: 0,
    laborBurdenPct: 0,
    commissionPct: 0,
    selected: 0,
    customer: {
      info: null,
    },
    job: {
      info: {},
    },
    workOrder: {
      info: {},
    },
  },
  materialEstimator: {
    laborRate: 0,
    laborBurdenPct: 0,
    commissionPct: 0,
    selected: 0,
  },
  unitEstimator: {
    laborRate: 0,
    laborBurdenPct: 0,
    commissionPct: 0,
    selected: 0,
  },
  subEstimator: {
    commissionPct: 0,
    selected: 0,
  },
};

let nextState = null;

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETESTIMATEONLYESTIMATOR':
      return { ...state, estimatedOnly: !state.estimatedOnly };
    case 'SETCUSTOMERJOBINFOESTIMATOR':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.gpmdEstimator.job.info = action.payload;
      return nextState;
    case 'SETCUSTOMERINFOESTIMATOR':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.gpmdEstimator.customer.info = action.payload;
      return nextState;
    case 'SETCONVERTWIDGETVALUESTATUS':
      return { ...state, ableToConvertFromWidgetToEstimate: action.payload };
    case 'CONVERTWIDGETVALUESTOESTIMATE':
      return { ...state, widgetEstimateValues: action.payload };
    case 'SETGPMDCONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.laborRate = parseFloat(action.payload.averageLaborHourlyRate).toFixed(
        2
      );
      nextState.gpmdEstimator.laborBurdenPct = parseFloat(
        action.payload.totalLaborBurdenAsPercentOfPay * 100
      ).toFixed(3);
      nextState.gpmdEstimator.commissionPct = parseFloat(
        action.payload.commissionPercent * 100
      ).toFixed(3);
      return nextState;
    case 'SETLABOURCONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.materialEstimator.laborRate = parseFloat(
        action.payload.averageLaborHourlyRate
      ).toFixed(2);
      nextState.materialEstimator.laborBurdenPct = parseFloat(
        action.payload.totalLaborBurdenAsPercentOfPay * 100
      ).toFixed(3);
      nextState.materialEstimator.commissionPct = parseFloat(
        action.payload.commissionPercent * 100
      ).toFixed(3);
      return nextState;
    case 'SETUNITCONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.unitEstimator.laborRate = parseFloat(action.payload.averageLaborHourlyRate).toFixed(
        2
      );
      nextState.unitEstimator.laborBurdenPct = parseFloat(
        action.payload.totalLaborBurdenAsPercentOfPay * 100
      ).toFixed(3);
      nextState.unitEstimator.commissionPct = parseFloat(
        action.payload.commissionPercent * 100
      ).toFixed(3);
      return nextState;
    case 'SETSUBSCONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.subEstimator.laborRate = parseFloat(action.payload.averageLaborHourlyRate).toFixed(
        2
      );
      nextState.subEstimator.laborBurdenPct = parseFloat(
        action.payload.totalLaborBurdenAsPercentOfPay * 100
      ).toFixed(3);
      nextState.subEstimator.commissionPct = parseFloat(
        action.payload.commissionPercent * 100
      ).toFixed(3);
      return nextState;
    case 'CHANGECONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      nextState[action.payload.type][action.payload.key] = action.payload.value;
      return nextState;
    case 'SETCONSTANT':
      nextState = JSON.parse(JSON.stringify(state));
      if (action.payload.key === 'laborRate') {
        nextState[action.payload.type][action.payload.key] = parseFloat(
          action.payload.value
        ).toFixed(2);
      } else {
        nextState[action.payload.type][action.payload.key] = parseFloat(
          action.payload.value * 100
        ).toFixed(3);
      }

      return nextState;
    case 'RESETJOBID':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.jobId = 0;
      return nextState;
    case 'RESETWORKORDERID':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.workOrderId = 0;
      return nextState;
    case 'RESETGPMDESTIMATORFORM':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.customerId = 0;
      nextState.gpmdEstimator.jobId = 0;
      nextState.gpmdEstimator.workOrderId = 0;
      nextState.gpmdEstimator.customer.info = null;
      nextState.gpmdEstimator.job.info = {};
      nextState.gpmdEstimator.workOrder.info = {};
      nextState.estimatedOnly = false;
      return nextState;
    case 'RESETSITECOMMON':
      return initialState;
    case 'SETESTIMATEDTABLENAME':
      return { ...state, estimatedTable: action.payload };
    case 'RESETESTIMATEDTABLENAME':
      return { ...state, estimatedTable: null };
    case 'ESTIMATOR':
      return { ...state, estimator: !state.estimator };
    case 'SETCUSTOMERIDGPMDESTIMATOR':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.customerId = action.payload;
      return nextState;
    case 'SETJOBIDGPMDESTIMATOR':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.jobId = action.payload;
      return nextState;
    case 'SETWORKORDERIDGPMDESTIMATOR':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.gpmdEstimator.workOrderId = action.payload;
      return nextState;
    case 'GENERATECOGSTAB':
      nextState = JSON.parse(JSON.stringify(state));
      let tabs = action.payload.length
        ? action.payload.map(ele => {
            return {
              id: ele.companyIncomeSourceId,
              name: ele.incomeSourceName,
              ...ele,
            };
          })
        : [];

      if (tabs.length) {
        Promise.all(tabs).then(res => {
          nextState.tabList[3].children = res;
        });
      } else {
        nextState.tabList[3].children = [];
      }

      return nextState;
    case 'SETCOMPANYINFOSTATUS':
      return { ...state, companyInfoStatus: action.payload };

    case 'SETSTATELIST':
      return {
        ...state,
        stateList: action.payload,
      };
    case 'CONTRACTORSOURCE':
      nextState = JSON.parse(JSON.stringify(state));
      if (action.payload.subType === true) {
        if (action.payload.length > 0) nextState.contractorType = true;
        else nextState.contractorType = false;
      }
      if (action.payload.subType === false) {
        if (action.payload.length > 0) nextState.contractorType = true;
        else nextState.contractorType = false;
      }
      return nextState;
    case 'SETPHONETYPELIST':
      return {
        ...state,
        phoneTypeList: action.payload,
      };
    case 'GETCONSTANTS':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.constants = action.payload;
      if (nextState.constants[1]) {
        nextState.constants[1].value =
          Math.round((parseFloat(nextState.constants[1].value) + 0.00001) * 100) / 100;
      }

      return nextState;
    case 'SETCONTRACTORTYPE':
      return {
        ...state,
        contractorTypeList: action.payload,
      };
    case 'UPDATETAB':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.tabs[action.payload.key] = action.payload.value;

      $('.nav-link').removeClass(
        action.payload.key === 'activeItem' ? 'parent-tab-active' : 'child-tab-active'
      );
      action.payload.value &&
        $(`.nav-link[data-target="${action.payload.value}"]`).addClass(
          action.payload.key === 'activeItem' ? 'parent-tab-active' : 'child-tab-active'
        );
      return nextState;
    case 'SETJOBTYPELIST':
      return {
        ...state,
        jobTypeList: action.payload,
      };
    case 'SETWORTYPELIST':
      return {
        ...state,
        workTypeList: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
