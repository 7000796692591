const WEEK_START_END_KEYS = {
  start: 'DayOfWeekBusinessStart',
  end: 'DayOfWeekBusinessEnd',
};

const initialState = {
  startWeekSetting: {},
  endWeekSetting: {},
};

let nextState;

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETSTARTENDWEEKSETTINGS':
      nextState = JSON.parse(JSON.stringify(state));
      nextState.startWeekSetting =
        action.payload.find(record => record.item === WEEK_START_END_KEYS.start) || {};
      nextState.endWeekSetting =
        action.payload.find(record => record.item === WEEK_START_END_KEYS.end) || {};
      return nextState;
    default:
      return state;
  }
};

export default settingsReducer;
