const initialState = {
  data: [],
  searchOptions: {
    firstName: null,
    lastName: null,
    customerDisplayName: null,
  },
};

let nextState = null;

const customerSearchReducer = (state = initialState, action, originalState = initialState) => {
  switch (action.type) {
    case 'GETCUSTOMERSEARCH':
      nextState = JSON.parse(JSON.stringify(state));
      return nextState;
    case 'ADDSEARCH':
      nextState = JSON.parse(JSON.stringify(state));
      if (action.payload.value === '') {
        nextState.searchOptions[action.payload.key] = null;
      } else {
        nextState.searchOptions[action.payload.key] = action.payload.value;
      }

      return nextState;
    case 'SEARCH':
      nextState = JSON.parse(JSON.stringify(state));
      const getCustomerList = [];
      action.payload.map(val => {
        if (val.address1 !== '') {
          getCustomerList.push(val);
        }
      });
      nextState.data = getCustomerList;
      return nextState;
    case 'CLEARDATACUSTOMERSEARCH':
      return originalState;
    default:
      return state;
  }
};
export default customerSearchReducer;
