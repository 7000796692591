import {
  getCompanyInfo,
  updateCompanyInfo,
  updateAddressLite,
  updateContactLite,
} from 'clients/api';
import { toastr } from 'utils';
import Cookies from 'js-cookie';

export const getCompany = () => async dispatch => {
  try {
    const response = await getCompanyInfo({
      companyId: Cookies.get('companyId'),
    });
    const { rowVersion2 } = response.data;
    if (rowVersion2.length === 0) {
      const resWithRowVersionUpdate = await updateCompanyInfo(
        { companyId: Cookies.get('companyId') },
        response.data
      );
      if (resWithRowVersionUpdate.status === 200) {
        dispatch(getCompany(Cookies.get('companyId')));
      } else {
        return { type: 'ERROR', status: response.status };
      }
    } else {
      dispatch({ type: 'GETCOMPANYDETAILS', payload: response.data });
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const updateCompanySave = payload => async dispatch => {
  try {
    const response = await updateCompanyInfo({ companyId: Cookies.get('companyId') }, payload);
    if (response.status === 200) {
      try {
        try {
          dispatch(getCompany(Cookies.get('companyId')));
          toastr.success('Company Info updated');
          return dispatch({ type: 'UPDATECOMPANYSAVE', payload: { value: true } });
        } catch (error) {}
      } catch (error) {
        toastr.error('Error happened while updating company info');
        return { type: 'ERROR' };
      }
    } else {
      return { type: 'ERROR', status: response.status };
    }
  } catch (error) {
    return { type: 'ERROR', status: error.response.status };
  }
};

export const updateCompanyStatus = val => ({
  type: 'UPDATECOMPANYSAVE',
  payload: { value: false },
});
