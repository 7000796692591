import { getCompanyIncomeSourceInHouseCogsList, getConstants } from 'clients/api';
//cookies
import Cookies from 'js-cookie';

export const getInHouseCogsListPL = IdFromHeaders => async dispatch => {
  try {
    var companyId = Cookies.get('companyId');
    const response = await getCompanyIncomeSourceInHouseCogsList({
      companyId: companyId || IdFromHeaders,
    }); // gotten plus summary for company incomesource inhouse cogs list
    if (response.data) {
      dispatch({
        type: 'SETCOMPANYINCOMESOURCECOGSPL',
        payload: response.data,
      });
    }
  } catch (error) {
    // return { type: 'ERROR' };
  }
};

export const getConstantsList = (companyIdFromHeaders) => async dispatch => {

  const getCompanyId = companyId || companyIdFromHeaders
  try {
    var companyId = Cookies.get('companyId');
    const response = await getConstants({ companyId }); // gotten constants for company incomesource inhouse cogs list
    if (response.data) {
      dispatch({
        type: 'SETGETCONSTANTS',
        payload: response.data,
      });
    }
  } catch (error) {
    // return { type: 'ERROR' };
  }
};
