import moment from 'moment';
import sum from 'lodash/sum';
import sumBy from 'lodash/sumBy';
import meanBy from 'lodash/meanBy';

import { toast } from 'react-toastify';

import { WEEKS } from 'config/constants';

export const formatPhoneNumber = number => {
  if (number && number[0] === '(' && number[4] === ')') {
    return number;
  }
  if (number) {
    const formatedNumber =
      '(' + number.slice(0, 3) + ') ' + number.slice(3, 6) + '-' + number.slice(6, 10);
    return formatedNumber;
  }
};

export const calculateYearWeekNum = (startDate, endDate, companyBusinessStartDay) => {
  let weekStartDay = companyBusinessStartDay;
  const refIndex = WEEKS.findIndex(w => w.name === weekStartDay);
  if (moment(startDate).day() === refIndex && moment(endDate).day() === (refIndex + 6) % 7) {
    const thisYear = new Date(endDate).getFullYear();
    const start = new Date('1/1/' + thisYear);
    const defaultStart = moment(start.valueOf());
    const defaultBusinessYearStart =
      moment(defaultStart).day() < refIndex
        ? moment
            .unix(moment(defaultStart).unix() + 3600 * 24 * (refIndex - moment(defaultStart).day()))
            .format('YYYY-MM-DD')
        : moment
            .unix(moment(defaultStart).unix() - 3600 * 24 * (moment(defaultStart).day() - refIndex))
            .format('YYYY-MM-DD');
    const weekNumber =
      (moment(startDate).unix() - moment(defaultBusinessYearStart).unix()) / (3600 * 24 * 7) + 1;
    return Math.round(weekNumber);
  } else {
    return undefined;
  }
};

//function to set default date based on business week range when firstly move to business weekly mode
export const getBusinessWeekFromDateold = (date, companyBusinessStartDay) => {
  let weekStartDay = companyBusinessStartDay;
  const refIndex = WEEKS.findIndex(w => w.name === weekStartDay);
  const currentIndex = moment(date).day();
  if (moment(date).isValid()) {
    if (currentIndex < refIndex) {
      return [
        moment
          .unix(moment(date).unix() + 3600 * 24 * (refIndex - currentIndex - 7))
          .format('YYYY-MM-DD'),
        moment
          .unix(moment(date).unix() + 3600 * 24 * (refIndex - currentIndex) - 3600 * 24)
          .format('YYYY-MM-DD'),
      ];
    } else {
      return [
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex))
          .format('YYYY-MM-DD'),
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex) + 3600 * 24 * 6)
          .format('YYYY-MM-DD'),
      ];
    }
  }
};

export const getBusinessWeekFromDate = (date, companyBusinessStartDay) => {
  let weekStartDay = companyBusinessStartDay;
  const refIndex = WEEKS.findIndex(w => w.name === weekStartDay);
  const currentIndex = moment(date).day();
  var diff = currentIndex - refIndex;
  if (moment(date).isValid()) {
    if (diff < 0) {
      diff += 7;
    }
    const weekStartDate = moment(date).add(-1 * diff, 'days');
    const weekenddate = moment(weekStartDate).add(6, 'days');
    return [moment(weekStartDate).format('YYYY-MM-DD'), moment(weekenddate).format('YYYY-MM-DD')];
  }
};

export const getBusinessTwoWeekFromDateOld = (date, companyBusinessStartDay) => {
  let weekStartDay = companyBusinessStartDay;
  const refIndex = WEEKS.findIndex(w => w.name === weekStartDay);
  const currentIndex = moment(date).day();
  if (moment(date).isValid()) {
    if (currentIndex < refIndex) {
      return [
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex + 7))
          .format('YYYY-MM-DD'),
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex) + 3600 * 24 * 6)
          .format('YYYY-MM-DD'),
      ];
    } else {
      return [
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex + 7))
          .format('YYYY-MM-DD'),
        moment
          .unix(moment(date).unix() - 3600 * 24 * (currentIndex - refIndex) + 3600 * 24 * 6)
          .format('YYYY-MM-DD'),
      ];
    }
  }
};

export const getBusinessTwoWeekFromDate = (date, companyBusinessStartDay) => {
  let weekStartDay = companyBusinessStartDay;
  const refIndex = WEEKS.findIndex(w => w.name === weekStartDay);
  const currentIndex = moment(date).day();
  var diff = currentIndex - refIndex;
  if (moment(date).isValid()) {
    if (diff < 0) {
      diff += 7;
    }
    const weekStartDate = moment(date).add(-1 * diff, 'days');

    const weekenddate = moment(weekStartDate).add(13, 'days');

    return [moment(weekStartDate).format('YYYY-MM-DD'), moment(weekenddate).format('YYYY-MM-DD')];
  }
};

export const toastr = {
  success: string => toast.success(`✔️ ${string}`),
  error: string =>
    toast.error(`
  ❌ ${string}`),
  info: string => toast.info(`👌 ${string}`),
  warning: string =>
    toast.warning(`
  ⚠️ ${string}`),
  default: string => toast.default(`🚀 ${string}`),
  dark: string => toast.dark(`🦄 ${string}`),
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

export const getComparator = (order, orderBy) => {
  const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const getActualManDaysFromWorkOrderTimeSheetData = timeSheetArray => {
  const sum = sumBy(timeSheetArray, object => {
    return object.regularHours + object.overtimeHours + object.doubleTimeHours;
  });
  return sum / 8;
};

export const getCustomerAndProjectFromWorkOrderId = (customersList, jobs, aimmLiteJobId) => {
  const projectInfo = jobs.length > 0 && jobs.find(e => e.aimmLiteJobId === aimmLiteJobId);
  const customerInfo =
    customersList.length > 0 &&
    projectInfo &&
    customersList.find(e => e.customerId === projectInfo.customerId);
  return {
    project: projectInfo,
    customer: customerInfo,
  };
};

export const getValidNum = (x, n) => {
  return Number.isFinite(x) ? parseFloat(parseFloat(x).toFixed(n)) : x;
};

export const getActualLaborCostFromWorkOrderTimeSheetData = (
  timeSheetArray,
  employeeLists,
  employeeIncomeSourceInfo
) => {
  const timeSheetArrayWithActualLaborCost =
    timeSheetArray.length > 0 && employeeLists.length > 0 && employeeIncomeSourceInfo.length > 0
      ? timeSheetArray
          .map(record => {
            const employee = employeeLists.find(
              e => e.employeeId === record.companyInHouseIncomeSourceEmployeeId
            );
            if (!employee) {
              return null;
            }
            return {
              ...record,
              actualLaborCost:
                record.regularHours * employee.regRate +
                record.overtimeHours * employee.otRate +
                record.doubleTimeHours * employee.dotRate,
              incomeSource: employeeIncomeSourceInfo.find(
                e => e.companyIncomeSourceId === employee.companyIncomeSourceId
              ),
            };
          })
          .filter(e => !!e)
      : [];
  const totalActualLaborCost =
    timeSheetArrayWithActualLaborCost.length > 0
      ? sumBy(timeSheetArrayWithActualLaborCost, object => {
          if (object.incomeSource) {
            return (
              object.actualLaborCost *
              (1 +
                object.incomeSource.baseWorkersCompRate +
                object.incomeSource.employerPayrollTaxPercentOfPay)
            );
          }
        })
      : null;
  return parseInt(totalActualLaborCost);
};

export const numberWithCommas = x => {
  let parts = String(x).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  if (parts[1]) {
    parts[1] = parts[1].slice(0, 2);
    return parts.join('.');
  } else {
    return parts.join('.');
  }
};

export const parseNumber = (str, limitInputNumber) => {
  str = str.replace(/[^\d\.\-]/g, '');
  if (limitInputNumber) {
    let parts = String(str).split('.');
    parts[0] = parts[0].slice(0, limitInputNumber);
    str = parts.join('.');
    return parseFloat(str);
  } else {
    if (str.match(new RegExp('.', 'g'))?.filter(x => x == '.').length == 2) {
      return parseFloat(str);
    }
    if (str.charAt(1) == '.' && str.charAt(0) === '0') {
      return str;
    }

    if (str.slice(-1) == '.' || str.slice(-1) == '0') {
      return str;
    } else {
      if (isNaN(str)) {
        return 0;
      }
      return parseFloat(str);
    }
  }
};

export const parseDecimalNumber = (str, decimalInputNumber) => {
  str = str.replace(/[^\d.\-]/g, '');

  return str;
};

export const calcTotalIncome = incomeSource => {
  if (incomeSource.length === 0) return [null, null];
  const mIncomeArray = incomeSource.map(i =>
    Number.isFinite(i.monthlyIncomeAmount) ? i.monthlyIncomeAmount : 0
  );
  const wIncomeArray = incomeSource.map(i =>
    Number.isFinite(i.weeklyIncomeAmount) ? i.weeklyIncomeAmount : 0
  );

  return [sum(mIncomeArray), sum(wIncomeArray)];
};
export const getLaborTotalAvgs = employeeList => {
  if (!employeeList || employeeList.length === 0) return [0, 0, 0, 0, 0, 0, 0];
  const totalWeekHrs = sum(employeeList.map(e => e.averageHoursPerWeek));
  const totalAvgRate = meanBy(employeeList.map(e => e.hourlyWageRate));
  const totalCrewMen = employeeList.length;
  const avgHrsPerMan = meanBy(employeeList.map(e => e.averageHoursPerWeek));
  const totalAvgWeeklyPayroll = sum(employeeList.map(e => e.totalPay));
  const avgHrLaborCost = totalAvgWeeklyPayroll / totalWeekHrs;
  const totalAvgManDays = totalWeekHrs / 8;
  return [
    totalWeekHrs,
    totalAvgManDays,
    totalCrewMen,
    avgHrsPerMan,
    avgHrLaborCost,
    totalAvgWeeklyPayroll,
    totalAvgRate,
  ];
};

export const getSubTotalAvgs = subContractorsList => {
  if (!subContractorsList || subContractorsList.length === 0) return null;
  const detailedSubList = subContractorsList.map(e => {
    return {
      ...e,
      weeklyGrossIncome: (e.costOfGoodsSold + e.materialCost) * (1 + e.subMarkupPercent / 100),
      weeklyGrossProfit: (e.costOfGoodsSold + e.materialCost) * (e.subMarkupPercent / 100),
    };
  });
  // const totalWeeklyCosts = sum(detailedSubList.map(e => e.costOfGoodsSold + e.materialCost));
  const totalWeeklyCosts = sum(detailedSubList.map(e => e.costOfGoodsSold ));
  const totalWeeklyGrossIncome = sum(detailedSubList.map(e => e.weeklyGrossIncome));
  const totalWeeklyGrossProfit = sum(detailedSubList.map(e => e.weeklyGrossProfit));
  const totalMaterials = sum(detailedSubList.map(e => e.materialCost));
  const avgMarkupPercent =
    totalWeeklyCosts > 0 ? (totalWeeklyGrossProfit / totalWeeklyCosts) * 100 : null;

  return [
    avgMarkupPercent,
    totalWeeklyGrossIncome,
    totalWeeklyCosts,
    totalMaterials,
    totalWeeklyGrossProfit,
  ];
};

export const getTotalAvgBonusWeekly = (cogsInfo, totalLaborAvgs) => {
  return totalLaborAvgs
    ? parseFloat((cogsInfo.weeklyBonusPayPerCrewMember * totalLaborAvgs[2]) / totalLaborAvgs[0])
    : 0;
};

export const getAverageHourlyRateWithBonus = (cogsInfo, totalLaborAvgs, laborBurdenAvgHr) => {
  if (!totalLaborAvgs) return 0;
  let laborBurdenAvgHrUpdated = laborBurdenAvgHr > 0 ? laborBurdenAvgHr : 0;
  const avgBonusPerHr = getTotalAvgBonusWeekly(cogsInfo, totalLaborAvgs);
  const avgHrRateWithBonus =
    Number(totalLaborAvgs[4]) + Number(avgBonusPerHr) + Number(laborBurdenAvgHrUpdated);

  return parseFloat(avgHrRateWithBonus).toFixed(3);
};

export const getAverageHourlyBurden = (cogsInfo, totalLaborAvgs) => {
  if (!totalLaborAvgs) return 0;
  const avgBonusPerHr = getTotalAvgBonusWeekly(cogsInfo, totalLaborAvgs);

  return parseFloat(
    (avgBonusPerHr + totalLaborAvgs[6]) *
      (cogsInfo.employerPayrollTaxPercentOfPay + cogsInfo.baseWorkersCompRate)
  );
};

export const caclulateGrossProfitPredictorOutPuts = input => {
  let ActualProductionHoursOverEstimatedProductionHours =
    Number(input.ActualProductionHoursOverEstimatedProductionHours) / 100;
  let estimatedGrossProfitPerManDay = Number(input.estimatedGrossProfitPerManDay);
  let totalCrewMembers = Number(input.totalCrewMembers);
  let averageHoursPerCrewMember = Number(input.averageHoursPerCrewMember);
  let subcontractorPercentageOfGrossIncome =
    Number(input.subcontractorPercentageOfGrossIncome) / 100;
  let subcontractorMarkupPercentage = Number(input.subcontractorMarkupPercentage) / 100;
  let overheadCostsAmount = Number(input.overheadCostsAmount);
  let designIncome = Number(input.designIncome);
  let PayrollTaxWithholding = Number(input.PayrollTaxWithholding) / 100;
  let workersCompensationInsuranceRate = Number(input.workersCompensationInsuranceRate) / 100;
  let averageHourlyBaseWageRate = Number(input.averageHourlyBaseWageRate);
  let materialsCostPercentageOfGrossIncome =
    Number(input.materialsCostPercentageOfGrossIncome) / 100;
  let salesCommisionPercentage = Number(input.salesCommisionPercentage) / 100;

  let grossIncomeForLandscapeDesignerAmount = designIncome;
  let averageHourlyOvertimeWageRate = averageHourlyBaseWageRate * 1.5;
  let payrollCostsWithoutOvertimeBonus = averageHourlyBaseWageRate * averageHoursPerCrewMember;
  let estimatedManDays = (totalCrewMembers * averageHoursPerCrewMember) / 8;
  let actualManDays = estimatedManDays * ActualProductionHoursOverEstimatedProductionHours;
  let workersCompensationBurdenAmount =
    workersCompensationInsuranceRate * payrollCostsWithoutOvertimeBonus;
  let estimatedGrossProfit = estimatedManDays * estimatedGrossProfitPerManDay;
  let hoursAtBaseWageRate = Math.min(averageHoursPerCrewMember, 40);
  let hoursAtOvertimeWageRate = averageHoursPerCrewMember - hoursAtBaseWageRate;
  let payrollAtBaseWageRate = averageHourlyBaseWageRate * hoursAtBaseWageRate;
  let payrollAtOvertimeWageRate = averageHourlyOvertimeWageRate * hoursAtOvertimeWageRate;
  let payrollCostsWithOvertimeBonus = payrollAtOvertimeWageRate + payrollAtBaseWageRate;
  let incomeTaxWithholdingBurdenAmount = PayrollTaxWithholding * payrollCostsWithOvertimeBonus;
  let totalRegulatoryBurdenAmount =
    incomeTaxWithholdingBurdenAmount + workersCompensationBurdenAmount;
  let totalPayrollCosts = payrollCostsWithOvertimeBonus + totalRegulatoryBurdenAmount;
  let hourlyRealWagePerCrewMember = totalPayrollCosts / averageHoursPerCrewMember;
  let dailyRealWagePerCrewMember = hourlyRealWagePerCrewMember * 8;
  let estimatedLaborCost = dailyRealWagePerCrewMember * estimatedManDays;
  let actualLaborCost = actualManDays * dailyRealWagePerCrewMember;
  let actualLaborVsEstimatedLaborDifference = actualLaborCost - estimatedLaborCost;
  let actualGrossProfit = estimatedGrossProfit - actualLaborVsEstimatedLaborDifference;
  let actualGrossProfitPerManDay = actualGrossProfit / actualManDays;
  let grossProfitBeforeSalesCommission = actualGrossProfitPerManDay * estimatedManDays;
  let amountPerSalesShare = grossProfitBeforeSalesCommission / 100;
  let inhouseGrossProfitAfterSalesCommission = amountPerSalesShare * 100;
  let totalSharesOfGrossProfitBeforeSubcontractorCommission = (1 + salesCommisionPercentage) * 100;

  let inhouseLaborCostAmount = dailyRealWagePerCrewMember * estimatedManDays;
  let inhouseGrossProfitAmount = inhouseGrossProfitAfterSalesCommission;
  let inhouseSalesCommissionCostAmount = salesCommisionPercentage * inhouseGrossProfitAmount;
  let grossIncomeBeforeMaterialsCost =
    inhouseLaborCostAmount + inhouseSalesCommissionCostAmount + inhouseGrossProfitAmount;
  let grossIncomeForYourWorkersAmount =
    grossIncomeBeforeMaterialsCost / (1 - materialsCostPercentageOfGrossIncome);
  let grossIncomeTotalAmount =
    grossIncomeForYourWorkersAmount / (1 - subcontractorPercentageOfGrossIncome) + designIncome;
  let materialsCostAmount = grossIncomeForYourWorkersAmount - grossIncomeBeforeMaterialsCost;
  let materialsCostPercentageOfGrossIncomeOutput = materialsCostAmount / grossIncomeTotalAmount;
  let inhouseLaborPercentageOfGrossIncome = inhouseLaborCostAmount / grossIncomeTotalAmount;
  let grossIncomeForSubcontractorsAmount =
    grossIncomeTotalAmount - grossIncomeForYourWorkersAmount - designIncome;
  let grossIncomeForSubcontractorPercent =
    grossIncomeForSubcontractorsAmount / grossIncomeTotalAmount;
  let grossIncomeForLandscapeDesignerPercent =
    grossIncomeForLandscapeDesignerAmount / grossIncomeTotalAmount;
  let grossIncomeForYourWorkersPercent = grossIncomeForYourWorkersAmount / grossIncomeTotalAmount;
  let subcontractorsCostAmount =
    grossIncomeForSubcontractorsAmount / (1 + subcontractorMarkupPercentage);
  let subcontractorsCostPercentageOfGrossIncome = subcontractorsCostAmount / grossIncomeTotalAmount;
  let inhouseSalesCommissionPercentageOfGrossIncome =
    inhouseSalesCommissionCostAmount / grossIncomeTotalAmount;
  let grossProfitBeforeSubcontractorCommission =
    grossIncomeForSubcontractorsAmount - subcontractorsCostAmount;
  let amountPerSubcontractorShare =
    grossProfitBeforeSubcontractorCommission /
    totalSharesOfGrossProfitBeforeSubcontractorCommission;
  let subcontractorCosts = amountPerSubcontractorShare * 100;
  let subcontractorCommissionShares = totalSharesOfGrossProfitBeforeSubcontractorCommission - 100;
  let subcontractorCommissionAmount = subcontractorCommissionShares * amountPerSubcontractorShare;
  let subcontractorSalesCommissionCostAmount = subcontractorCommissionAmount;
  let subcontractorSalesCommissionPercentageOfGrossIncome =
    subcontractorSalesCommissionCostAmount / grossIncomeTotalAmount;
  let costOfGoodsSoldTotalAmount =
    materialsCostAmount +
    inhouseLaborCostAmount +
    subcontractorsCostAmount +
    inhouseSalesCommissionCostAmount +
    subcontractorSalesCommissionCostAmount;
  let costOfGoodsSoldTotalPercentageOfGrossIncome =
    costOfGoodsSoldTotalAmount / grossIncomeTotalAmount;
  let inhouseGrossProfitPercentageOfInhouseGrossIncome =
    inhouseGrossProfitAmount / grossIncomeForYourWorkersAmount;
  let subcontractorsGrossProfitAmount = subcontractorCosts;
  let grossProfitTotalAmount =
    inhouseGrossProfitAmount + subcontractorsGrossProfitAmount + designIncome;
  let subcontractorsGrossProfitPercentageOfSubcontractorsCost =
    subcontractorPercentageOfGrossIncome === 0
      ? 0
      : subcontractorsGrossProfitAmount / grossIncomeForSubcontractorsAmount;
  let grossProfitTotalPercentageOfGrossIncome = grossProfitTotalAmount / grossIncomeTotalAmount;
  let overheadCostsPercentageOfGrossIncome = overheadCostsAmount / grossIncomeTotalAmount;
  let netIncomeAmount = grossProfitTotalAmount - overheadCostsAmount;
  let netIncomePercentageOfGrossIncome = netIncomeAmount / grossIncomeTotalAmount;

  input.grossIncomeForLandscapeDesignerAmount =
    isNaN(grossIncomeForLandscapeDesignerAmount) ||
    grossIncomeForLandscapeDesignerAmount === Infinity
      ? 0
      : grossIncomeForLandscapeDesignerAmount;
  input.grossIncomeForYourWorkersAmount =
    isNaN(grossIncomeForYourWorkersAmount) || grossIncomeForYourWorkersAmount === Infinity
      ? 0
      : grossIncomeForYourWorkersAmount;
  input.estimatedManDays =
    isNaN(estimatedManDays) || estimatedManDays === Infinity ? 0 : estimatedManDays;
  input.grossIncomeForYourWorkersPercent =
    isNaN(grossIncomeForYourWorkersPercent) || grossIncomeForYourWorkersPercent === Infinity
      ? 0
      : grossIncomeForYourWorkersPercent;
  input.grossIncomeForLandscapeDesignerPercent =
    isNaN(grossIncomeForLandscapeDesignerPercent) ||
    grossIncomeForLandscapeDesignerPercent === Infinity
      ? 0
      : grossIncomeForLandscapeDesignerPercent;
  input.grossIncomeForSubcontractorsAmount =
    isNaN(grossIncomeForSubcontractorsAmount) || grossIncomeForSubcontractorsAmount === Infinity
      ? 0
      : grossIncomeForSubcontractorsAmount;
  input.grossIncomeForSubcontractorPercent =
    isNaN(grossIncomeForSubcontractorPercent) || grossIncomeForSubcontractorPercent === Infinity
      ? 0
      : grossIncomeForSubcontractorPercent;
  input.grossIncomeTotalAmount =
    isNaN(grossIncomeTotalAmount) || grossIncomeTotalAmount === Infinity
      ? 0
      : grossIncomeTotalAmount;
  input.materialsCostAmount =
    isNaN(materialsCostAmount) || materialsCostAmount === Infinity ? 0 : materialsCostAmount;
  input.materialsCostPercentageOfGrossIncomeOutput =
    isNaN(materialsCostPercentageOfGrossIncomeOutput) ||
    materialsCostPercentageOfGrossIncomeOutput === Infinity
      ? 0
      : materialsCostPercentageOfGrossIncomeOutput;
  input.inhouseLaborCostAmount =
    isNaN(inhouseLaborCostAmount) || inhouseLaborCostAmount === Infinity
      ? 0
      : inhouseLaborCostAmount;
  input.inhouseLaborPercentageOfGrossIncome =
    isNaN(inhouseLaborPercentageOfGrossIncome) || inhouseLaborPercentageOfGrossIncome === Infinity
      ? 0
      : inhouseLaborPercentageOfGrossIncome;
  input.subcontractorsCostAmount =
    isNaN(subcontractorsCostAmount) || subcontractorsCostAmount === Infinity
      ? 0
      : subcontractorsCostAmount;
  input.subcontractorsCostPercentageOfGrossIncome =
    isNaN(subcontractorsCostPercentageOfGrossIncome) ||
    subcontractorsCostPercentageOfGrossIncome === Infinity
      ? 0
      : subcontractorsCostPercentageOfGrossIncome;
  input.inhouseSalesCommissionCostAmount =
    isNaN(inhouseSalesCommissionCostAmount) || inhouseSalesCommissionCostAmount === Infinity
      ? 0
      : inhouseSalesCommissionCostAmount;
  input.inhouseSalesCommissionPercentageOfGrossIncome =
    isNaN(inhouseSalesCommissionPercentageOfGrossIncome) ||
    inhouseSalesCommissionPercentageOfGrossIncome === Infinity
      ? 0
      : inhouseSalesCommissionPercentageOfGrossIncome;
  input.subcontractorSalesCommissionCostAmount =
    isNaN(subcontractorSalesCommissionCostAmount) ||
    subcontractorSalesCommissionCostAmount === Infinity
      ? 0
      : subcontractorSalesCommissionCostAmount;
  input.subcontractorSalesCommissionPercentageOfGrossIncome =
    isNaN(subcontractorSalesCommissionPercentageOfGrossIncome) ||
    subcontractorSalesCommissionPercentageOfGrossIncome === Infinity
      ? 0
      : subcontractorSalesCommissionPercentageOfGrossIncome;
  input.costOfGoodsSoldTotalAmount =
    isNaN(costOfGoodsSoldTotalAmount) || costOfGoodsSoldTotalAmount === Infinity
      ? 0
      : costOfGoodsSoldTotalAmount;
  input.costOfGoodsSoldTotalPercentageOfGrossIncome =
    isNaN(costOfGoodsSoldTotalPercentageOfGrossIncome) ||
    costOfGoodsSoldTotalPercentageOfGrossIncome === Infinity
      ? 0
      : costOfGoodsSoldTotalPercentageOfGrossIncome;
  input.inhouseGrossProfitAmount =
    isNaN(inhouseGrossProfitAmount) || inhouseGrossProfitAmount === Infinity
      ? 0
      : inhouseGrossProfitAmount;
  input.inhouseGrossProfitPercentageOfInhouseGrossIncome =
    isNaN(inhouseGrossProfitPercentageOfInhouseGrossIncome) ||
    inhouseGrossProfitPercentageOfInhouseGrossIncome === Infinity
      ? 0
      : inhouseGrossProfitPercentageOfInhouseGrossIncome;
  input.subcontractorsGrossProfitAmount =
    isNaN(subcontractorsGrossProfitAmount) || subcontractorsGrossProfitAmount === Infinity
      ? 0
      : subcontractorsGrossProfitAmount;
  input.subcontractorsGrossProfitPercentageOfSubcontractorsCost =
    isNaN(subcontractorsGrossProfitPercentageOfSubcontractorsCost) ||
    subcontractorsGrossProfitPercentageOfSubcontractorsCost === Infinity
      ? 0
      : subcontractorsGrossProfitPercentageOfSubcontractorsCost;
  input.grossProfitTotalAmount =
    isNaN(grossProfitTotalAmount) || grossProfitTotalAmount === Infinity
      ? 0
      : grossProfitTotalAmount;
  input.grossProfitTotalPercentageOfGrossIncome =
    isNaN(grossProfitTotalPercentageOfGrossIncome) ||
    grossProfitTotalPercentageOfGrossIncome === Infinity
      ? 0
      : grossProfitTotalPercentageOfGrossIncome;
  input.overheadCostsAmount =
    isNaN(overheadCostsAmount) || overheadCostsAmount === Infinity ? 0 : overheadCostsAmount;
  input.overheadCostsPercentageOfGrossIncome =
    isNaN(overheadCostsPercentageOfGrossIncome) || overheadCostsPercentageOfGrossIncome === Infinity
      ? 0
      : overheadCostsPercentageOfGrossIncome;
  input.netIncomeAmount =
    isNaN(netIncomeAmount) || netIncomeAmount === Infinity ? 0 : netIncomeAmount;
  input.netIncomePercentageOfGrossIncome =
    isNaN(netIncomePercentageOfGrossIncome) || netIncomePercentageOfGrossIncome === Infinity
      ? 0
      : netIncomePercentageOfGrossIncome;
  input.actualGrossProfitPerManDay =
    isNaN(actualGrossProfitPerManDay) || actualGrossProfitPerManDay === Infinity
      ? 0
      : actualGrossProfitPerManDay;

  return Object.assign({}, input);
};

export const calculateWorkOrderTableGpmd = workOrderTableObj => {
  let laborCost = 8 * Number(workOrderTableObj.laborRate) * Number(workOrderTableObj.manDays);
  let laborBurden = parseFloat(laborCost) * Number(workOrderTableObj.laborBurdenPercent);
  let totalLaborCost = laborCost + laborBurden;
  let actualManDays = Number(workOrderTableObj.actualManDays);

  let totalCostWithoutCommission = Number(workOrderTableObj.materialCost) + totalLaborCost;
  let rawGp = Number(workOrderTableObj.price) - totalCostWithoutCommission;
  let commission =
    rawGp *
    (Number(workOrderTableObj.commissionPercent) /
      (1 + Number(workOrderTableObj.commissionPercent)));
  let totalGp = rawGp - commission;
  let inHouseGp = totalGp;
  let gpmd =
    Number(workOrderTableObj.manDays) > 0 ? inHouseGp / Number(workOrderTableObj.manDays) : 0;
  let actualTotalLaborCost = Number(workOrderTableObj.actualLaborCost);
  let actualTotalCostWithoutCommission =
    Number(workOrderTableObj.actualMaterialCost) +
    actualTotalLaborCost +
    Number(workOrderTableObj.actualSubCost);
  let actualRawGp = Number(workOrderTableObj.price) - actualTotalCostWithoutCommission;
  let actualCommission =
    actualRawGp *
    (Number(workOrderTableObj.commissionPercent) /
      (1 + Number(workOrderTableObj.commissionPercent)));
  let actualTotalGp = actualRawGp - actualCommission;
  let actualInHouseGp = actualTotalGp - Number(workOrderTableObj.actualSubGp);
  let actualGpmd = Number(actualManDays) > 0 ? actualInHouseGp / Number(actualManDays) : 0;

  // return values
  workOrderTableObj.laborCost =
    isNaN(totalLaborCost) || totalLaborCost === Infinity ? 0 : totalLaborCost.toFixed(0);
  workOrderTableObj.commissionCost =
    isNaN(commission) || commission === Infinity ? 0 : commission.toFixed(0);
  workOrderTableObj.inhouseGp =
    isNaN(inHouseGp) || inHouseGp === Infinity ? 0 : inHouseGp.toFixed(0);
  workOrderTableObj.totalGp = isNaN(totalGp) || totalGp === Infinity ? 0 : totalGp.toFixed(0);
  workOrderTableObj.gpmd = isNaN(gpmd) || gpmd === Infinity ? 0 : gpmd.toFixed(0);

  //return actual values

  workOrderTableObj.actualCommissionCost =
    isNaN(actualCommission) || actualCommission === Infinity ? 0 : actualCommission.toFixed(0);
  workOrderTableObj.actualInHouseGp =
    isNaN(actualInHouseGp) || actualInHouseGp === Infinity ? 0 : actualInHouseGp.toFixed(0);
  workOrderTableObj.actualTotalGp =
    isNaN(actualTotalGp) || actualTotalGp === Infinity ? 0 : actualTotalGp.toFixed(0);
  workOrderTableObj.actualGpmd =
    isNaN(actualGpmd) || actualGpmd === Infinity ? 0 : actualGpmd.toFixed(0);

  return Object.assign({}, workOrderTableObj);
};

export const calculatePrice = workOrderState => {
  let manDays = Number(workOrderState.manDays);
  let materialCost = Number(workOrderState.materialCost);
  let gpmd = Number(workOrderState.gpmd);

  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPercent
    ? workOrderState.laborBurdenPercent / 100
    : 0;
  let commissionPercent = workOrderState.commissionPercent
    ? workOrderState.commissionPercent / 100
    : 0;

  let laborCostOnly = Number(manDays) * 8 * Number(laborRate);
  let laborBurden = Number(laborCostOnly) * Number(laborBurdenPercent);
  let totalLaborCost = Number(laborCostOnly) + Number(laborBurden);

  let costWithoutCommission = Number(materialCost) + Number(totalLaborCost);
  let inHouseGp = Number(manDays) * Number(gpmd);
  let totalGp = Number(inHouseGp);
  let commission = Number(totalGp) * Number(commissionPercent);
  let price = Number(costWithoutCommission) + Number(totalGp) + Number(commission);

  workOrderState.laborCost = totalLaborCost.toFixed(0);
  workOrderState.inhouseGp = inHouseGp.toFixed(0);
  workOrderState.totalGp = totalGp;
  workOrderState.commissionCost = commission ? commission.toFixed(0) : 0;
  workOrderState.price = price ? Number(price).toFixed(0) : 0;

  return workOrderState;
};

export const calculateManDays = workOrderState => {
  let materialCost = Number(workOrderState.materialCost);

  let gpmd = Number(workOrderState.gpmd);
  let price = Number(workOrderState.price);

  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPercent / 100;
  let commissionPercent = workOrderState.commissionPercent / 100;

  let dailyLaborCostOnly = 8 * parseFloat(laborRate);
  let dailyLaborBurden = parseFloat(dailyLaborCostOnly) * parseFloat(laborBurdenPercent);
  let laborCostPerManDay = parseFloat(dailyLaborCostOnly) + parseFloat(dailyLaborBurden);

  let dailyComissionCost = parseFloat(gpmd) * parseFloat(commissionPercent);
  let dailyEmployeeCost = parseFloat(laborCostPerManDay) + parseFloat(dailyComissionCost);

  let priceLessMaterialsAndSubs = parseFloat(price) - parseFloat(materialCost);
  let manDays =
    parseFloat(parseFloat(dailyEmployeeCost) + parseFloat(gpmd)) === 0
      ? 0
      : parseFloat(parseFloat(priceLessMaterialsAndSubs)) /
        parseFloat(parseFloat(dailyEmployeeCost) + parseFloat(gpmd));

  let laborCostOnly = parseFloat(manDays) * 8 * parseFloat(laborRate);
  let laborBurden = parseFloat(laborCostOnly) * parseFloat(laborBurdenPercent);
  let totalLaborCost = parseFloat(laborCostOnly) + parseFloat(laborBurden);

  let commission = parseFloat(parseFloat(manDays) * parseFloat(dailyComissionCost));
  let totalGp =
    parseFloat(price) -
    parseFloat(materialCost) -
    parseFloat(totalLaborCost) -
    parseFloat(commission);
  let inHouseGp = parseFloat(totalGp);

  workOrderState.manDays = isNaN(manDays) || manDays === Infinity ? 0 : manDays.toFixed(0);
  workOrderState.inhouseGp = isNaN(inHouseGp) || inHouseGp === Infinity ? 0 : inHouseGp.toFixed(0);
  workOrderState.laborCost =
    isNaN(totalLaborCost) || totalLaborCost === Infinity ? 0 : totalLaborCost.toFixed(0);
  workOrderState.totalGp = isNaN(totalGp) || totalGp === Infinity ? 0 : totalGp.toFixed(0);
  workOrderState.commissionCost =
    isNaN(commission) || commission === Infinity ? 0 : commission.toFixed(0);
  return workOrderState;
};

export const calculateGpmd = workOrderState => {
  let materialCost = Number(workOrderState.materialCost);
  let manDays = Number(workOrderState.manDays);
  let price = Number(workOrderState.price);

  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPercent
    ? workOrderState.laborBurdenPercent / 100
    : 0;
  let commissionPercent = workOrderState.commissionPercent
    ? workOrderState.commissionPercent / 100
    : 0;

  let dailyLaborCostOnly = 8 * parseFloat(laborRate);
  let dailyLaborBurden = parseFloat(dailyLaborCostOnly) * parseFloat(laborBurdenPercent);
  let laborCostPerManDay = parseFloat(dailyLaborCostOnly) + parseFloat(dailyLaborBurden);

  let totalLaborCost = parseFloat(laborCostPerManDay) * parseFloat(manDays);
  let rawGp = parseFloat(price) - parseFloat(materialCost) - parseFloat(totalLaborCost);

  let commission =
    parseFloat(rawGp) *
    ((parseFloat(commissionPercent) * 100) / (100 + parseFloat(commissionPercent) * 100));

  let totalGp = parseFloat(rawGp) - parseFloat(commission);
  let inHouseGp = parseFloat(totalGp);

  let gpmd = parseFloat(manDays) === 0 ? 0 : parseFloat(inHouseGp) / parseFloat(manDays);

  workOrderState.gpmd = gpmd ? gpmd.toFixed(0) : 0;
  workOrderState.inhouseGp = inHouseGp ? inHouseGp.toFixed(0) : 0;
  workOrderState.laborCost = totalLaborCost ? totalLaborCost.toFixed(0) : 0;
  workOrderState.totalGp = totalGp ? totalGp.toFixed(0) : 0;
  workOrderState.commissionCost = commission ? commission.toFixed(0) : 0;
  return workOrderState;
};

export const calculateData = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent =
    workOrderState.laborBurdenPercent && workOrderState.laborBurdenPercent / 100;
  let commissionPercent = workOrderState.commissionPercent / 100;

  let manDays = Number(workOrderState.manDays);
  let laborMarkupPercent = Number(workOrderState.laborMarkupPercent);
  let materialCost = Number(workOrderState.materialCost);
  let materialMarkupPercent = Number(workOrderState.materialMarkupPercent);

  let laborCost = parseFloat(manDays) * 8 * parseFloat(laborRate);
  let laborBurden = parseFloat(laborCost) * parseFloat(laborBurdenPercent);
  let totalLaborCost = parseFloat(laborCost) + parseFloat(laborBurden);

  let laborGp = parseFloat(totalLaborCost) * (parseFloat(laborMarkupPercent) / 100);
  let materialGp = parseFloat(materialCost) * (parseFloat(materialMarkupPercent) / 100);
  let totalGp = parseFloat(laborGp) + parseFloat(materialGp);
  let totalCommissionCost = totalGp * commissionPercent;
  let totalprice =
    parseFloat(materialCost) + parseFloat(totalLaborCost) + totalGp + totalCommissionCost;
  let gpmd = parseFloat(manDays) === 0 ? 0 : parseFloat(totalGp) / parseFloat(manDays);

  workOrderState.laborCost = totalLaborCost.toFixed(0);
  workOrderState.laborGp = laborGp.toFixed(0);
  workOrderState.materialGp = materialGp.toFixed(0);
  workOrderState.totalGp = totalGp.toFixed(0);
  workOrderState.commissionCost = totalCommissionCost.toFixed(0);
  workOrderState.price = totalprice.toFixed(0);
  workOrderState.gpmd = gpmd.toFixed(0);

  return workOrderState;
};

export const calculateDataMandays = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = Number(workOrderState.laborBurdenPercent).toFixed(4) / 100;
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;

  let price = Number(workOrderState.price);
  let materialMarkupPercent = Number(workOrderState.materialMarkupPercent);
  let gpmd = Number(workOrderState.gpmd);
  let materialCost = Number(workOrderState.materialCost);

  let laborCost = 8 * parseFloat(laborRate);
  let laborBurden = laborCost * parseFloat(laborBurdenPercent);
  let totalLaborCost = laborCost + laborBurden;

  let materialGp = parseFloat(materialCost) * (parseFloat(materialMarkupPercent) / 100);
  let dailyCommissionCost = parseFloat(gpmd) * commissionPercent;
  let totalMDCost = totalLaborCost + dailyCommissionCost + parseFloat(gpmd);
  let manDays = totalMDCost === 0 ? 0 : (price - materialCost) / totalMDCost;
  let finalLaborCost = totalLaborCost * manDays;

  let gpWithCommission = parseFloat(price) - parseFloat(materialCost) - totalLaborCost * manDays;
  let gpParts = 100 + parseFloat(commissionPercent) * 100;
  let eachPart = gpWithCommission / gpParts;
  let totalGp = eachPart * 100;
  let laborGp = totalGp - materialGp;

  let finalCommission = gpWithCommission - totalGp;

  workOrderState.manDays = manDays.toFixed(0);
  workOrderState.laborGp = laborGp.toFixed(0);
  workOrderState.laborCost = finalLaborCost.toFixed(0);
  workOrderState.totalGp = totalGp.toFixed(0);
  workOrderState.commissionCost = finalCommission.toFixed(0);
  workOrderState.materialGp = materialGp.toFixed(0);

  return workOrderState;
};

export const calculateDataGpmd = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = Number(workOrderState.laborBurdenPercent).toFixed(4) / 100;
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;

  let manDays = Number(workOrderState.manDays);
  let price = Number(workOrderState.price);
  let materialCost = Number(workOrderState.materialCost);
  let materialMarkupPercent = Number(workOrderState.materialMarkupPercent);

  let laborCost = 8 * parseFloat(laborRate);
  let laborBurden = laborCost * parseFloat(laborBurdenPercent);
  let totalLaborCost = laborCost + laborBurden;

  let materialGp = parseFloat(materialCost) * (parseFloat(materialMarkupPercent) / 100);
  let totalOnlyLoborCost = totalLaborCost * parseFloat(manDays);
  let rawGp = price - materialCost - totalOnlyLoborCost;
  let totalCommissionCost =
    (rawGp * parseFloat(commissionPercent)) / (1 + parseFloat(commissionPercent));
  let totalGp = rawGp - totalCommissionCost;
  let gpmd = parseFloat(manDays) === 0 ? 0 : totalGp / parseFloat(manDays);
  let laborGp = totalGp - materialGp;

  workOrderState.laborCost = totalOnlyLoborCost.toFixed(0);
  workOrderState.commissionCost = totalCommissionCost.toFixed(0);
  workOrderState.totalGp = totalGp.toFixed(0);
  workOrderState.gpmd = gpmd.toFixed(0);
  workOrderState.laborGp = laborGp.toFixed(0);
  workOrderState.materialGp = materialGp.toFixed(0);

  return workOrderState;
};

export const calculatePriceUnit = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = Number(workOrderState.laborBurdenPercent).toFixed(4) / 100;
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;

  let manDays = Number(workOrderState.manDays);
  let gpmd = Number(workOrderState.gpmd);
  let materialCost = Number(workOrderState.materialCost);
  let quantity = Number(workOrderState.quantity);

  let laborCostOnly = 8 * parseFloat(laborRate);
  let laborBurden = laborCostOnly * parseFloat(laborBurdenPercent);
  let dailyLaborCost = laborCostOnly + laborBurden;

  let totalLaborCost = dailyLaborCost * parseFloat(manDays);
  let totalGp = parseFloat(manDays) * parseFloat(gpmd);
  let commissionCost = totalGp * parseFloat(commissionPercent);

  let price = parseFloat(materialCost) + totalLaborCost + commissionCost + totalGp;
  let unitPrice = parseFloat(quantity) === 0 ? 0 : price / parseFloat(quantity);

  workOrderState.laborCost = totalLaborCost.toFixed(0);
  workOrderState.totalGp = totalGp.toFixed(0);
  workOrderState.price = price.toFixed(0);
  workOrderState.commissionCost = commissionCost.toFixed(0);
  workOrderState.unitPrice = unitPrice.toFixed(0);
  return workOrderState;
};

export const calculateGPMDUnit = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPercent / 100;
  let commissionPercent = workOrderState.commissionPercent / 100;

  let unitPrice = Number(workOrderState.unitPrice);
  let quantity = Number(workOrderState.quantity).toFixed(0);
  let materialCost = Number(workOrderState.materialCost);
  let manDays = Number(workOrderState.manDays);

  let dailyLaborCost = 8 * parseFloat(laborRate);
  let dailyLaborBurden = dailyLaborCost * parseFloat(laborBurdenPercent);
  let MDLabourTotal = dailyLaborCost + dailyLaborBurden;

  let price = parseFloat(unitPrice) * parseFloat(quantity);
  let laborCost = parseFloat(manDays) * MDLabourTotal;

  let rawGp = price - parseFloat(materialCost) - laborCost;
  let commissionCost =
    rawGp * ((parseFloat(commissionPercent) * 100) / (parseFloat(commissionPercent) * 100 + 100));
  let gp = rawGp - commissionCost;
  let gpmd = manDays > 0 ? gp / parseFloat(manDays) : 0;

  workOrderState.laborCost = laborCost.toFixed(0);
  workOrderState.price = price.toFixed(0);
  workOrderState.commissionCost = commissionCost.toFixed(0);
  workOrderState.totalGp = gp.toFixed(0);
  workOrderState.gpmd = gpmd.toFixed(0);

  return workOrderState;
};

export const calulateManDaysUnit = workOrderState => {
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = Number(workOrderState.laborBurdenPercent).toFixed(4) / 100;
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;

  let gpmd = Number(workOrderState.gpmd);
  let unitPrice = Number(workOrderState.unitPrice);
  let quantity = Number(workOrderState.quantity);
  let materialCost = Number(workOrderState.materialCost);

  let dailyLaborCost = 8 * parseFloat(laborRate);
  let dailyLaborBurden = dailyLaborCost * parseFloat(laborBurdenPercent);

  let MDLaborTotal = dailyLaborCost + dailyLaborBurden;
  let dailyCommissionCost = parseFloat(gpmd) * parseFloat(commissionPercent);
  let totalDailyCost = parseFloat(gpmd) + MDLaborTotal + dailyCommissionCost;

  let price = parseFloat(unitPrice) * parseFloat(quantity);
  let priceLessMaterial = price - parseFloat(materialCost);
  let manDays = totalDailyCost === 0 ? 0 : priceLessMaterial / totalDailyCost;
  let laborCost = manDays * MDLaborTotal;
  let rawGp = price - parseFloat(materialCost) - laborCost;
  let commissionCost =
    rawGp * ((parseFloat(commissionPercent) * 100) / (parseFloat(commissionPercent) * 100 + 100));

  let gp = rawGp - commissionCost;

  workOrderState.manDays = manDays.toFixed(0);
  workOrderState.laborCost = laborCost.toFixed(0);
  workOrderState.price = price.toFixed(0);
  workOrderState.commissionCost = commissionCost.toFixed(0);
  workOrderState.totalGp = gp.toFixed(0);

  return workOrderState;
};

export const calculateSubsOnlyBar = workOrderState => {
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;

  let subCost = Number(workOrderState.subCost);
  let subMarkupPercent = Number(workOrderState.subMarkupPercent / 100);
  let materialCost = Number(workOrderState.materialCost);
  let subDays = Number(workOrderState.subDays);

  let subGp = subMarkupPercent * subCost;
  let commissionCost = parseFloat(subGp) * parseFloat(commissionPercent);
  let price = parseFloat(materialCost) + parseFloat(subCost) + parseFloat(subGp) + commissionCost;

  let gpsd = parseFloat(subDays) === 0 ? 0 : parseFloat(subGp) / parseFloat(subDays);

  let totalGp = subGp;

  workOrderState.commissionCost = Number(commissionCost).toFixed(0);
  workOrderState.price = Number(price).toFixed(0);
  workOrderState.subGp = Number(subGp).toFixed(0);
  workOrderState.totalGp = Number(totalGp);
  workOrderState.gpsd = Number(gpsd).toFixed(0);

  return workOrderState;
};

export const calculateSubGpFromSubsOnly = workOrderState => {
  let price = Number(workOrderState.price);
  let commissionPercent = Number(workOrderState.commissionPercent).toFixed(4) / 100;
  let subCost = Number(workOrderState.subCost);
  let materialCost = Number(workOrderState.materialCost);
  let subDays = Number(workOrderState.subDays);

  let totalCostWithoutCommission = materialCost + subCost;
  let rawGp = price - totalCostWithoutCommission;
  let commissionCost = rawGp / (1 + 1 / commissionPercent);
  let subGp = rawGp - commissionCost;
  let gpsd = subDays === 0 ? 0 : subGp / subDays;

  workOrderState.subGp = subGp.toFixed(0);
  workOrderState.commissionCost = commissionCost.toFixed(0);
  workOrderState.gpsd = gpsd.toFixed(0);
  workOrderState.price = price.toFixed(0);

  return workOrderState;
};

export const calculateSubsOnlyFromSubGp = workOrderState => {
  let commissionPercent = Number(workOrderState.commissionPercent) / 100;
  let subCost = Number(workOrderState.subCost);
  let subGp = Number(workOrderState.subGp);
  let materialCost = Number(workOrderState.materialCost);
  let subDays = Number(workOrderState.subDays);

  let actualSubCost = Number(workOrderState.actualSubCost);
  let actualSubDays = Number(workOrderState.actualSubDays);
  let actualMaterialCost = Number(workOrderState.actualMaterialCost);

  let subMarkupPercent = subCost === 0 ? 0 : subGp / subCost;
  let commissionCost = parseFloat(subGp) * parseFloat(commissionPercent);
  let price = parseFloat(materialCost) + parseFloat(subCost) + parseFloat(subGp) + commissionCost;
  let gpsd = parseFloat(subDays) === 0 ? 0 : parseFloat(subGp) / parseFloat(subDays);

  let actualTotalCostWithoutCommission = actualMaterialCost + actualSubCost;
  let actualRawGp = price - actualTotalCostWithoutCommission;

  let actualCommissionCost = actualRawGp * (commissionPercent / (1 + commissionPercent));
  let actualSubGp = actualRawGp - actualCommissionCost;
  let actualSubMarkupPercent = actualSubCost === 0 ? 0 : actualSubGp / actualSubCost;

  let actualGpsd =
    parseFloat(actualSubDays) === 0 ? 0 : parseFloat(actualSubGp) / parseFloat(actualSubDays);

  let totalGp = subGp;
  workOrderState.commissionCost = commissionCost.toFixed(0);
  workOrderState.price = price.toFixed(0);
  workOrderState.totalGp = totalGp.toFixed(0);
  workOrderState.gpsd = gpsd.toFixed(0);
  workOrderState.subMarkupPercent = subMarkupPercent.toFixed(2);

  //actual values
  workOrderState.actualSubMarkupPercent = actualSubMarkupPercent.toFixed(2);
  workOrderState.actualCommissionCost = actualCommissionCost.toFixed(0);
  workOrderState.actualGpsd = actualGpsd.toFixed(0);
  workOrderState.actualSubGp = actualSubGp.toFixed(0);

  return workOrderState;
};

export const getTotalCrewLabourBurden = (id, state) => {
  let val = 0;
  val =
    (parseFloat(state.nonSubContractor.cogsInfo[id].totalDirectWeeklyPayroll) +
      parseFloat(state.nonSubContractor.cogsInfo[id].totalAvgWeeklyBonus)) *
    (parseFloat(state.nonSubContractor.cogsInfo[id].totalLaborBurdenAsPercentOfPay) / 100);

  val = val.toFixed(0);
  state.nonSubContractor.cogsInfo[id].totalCrewBurdenAsPercentageOfPay = val;
  return numberWithCommas(val);
};

export const getTotalRentalOfJob = (id, state) => {
  let val = 0;
  val =
    parseFloat(state.nonSubContractor.cogsInfo[id].materialCostAsPercentageOfPrice) +
    parseFloat(state.nonSubContractor.cogsInfo[id].rentalCostAsPercentOfJobPrice);

  val = val.toFixed(0);
  state.nonSubContractor.cogsInfo[id].materialMarkupPercent = val;
  return numberWithCommas(val);
};

export const calculateWorkOrderTableGpmd2 = (workOrderTableObj, constants, state) => {
  let laborCost =
    8 *
    parseFloat(String(workOrderTableObj.manDays).replace(/,/g, '')) *
    parseFloat(String(workOrderTableObj.laborRate).replace(/,/g, ''));
  let laborBurden =
    laborCost * parseFloat(String(workOrderTableObj.laborBurdenPercent).replace(/,/g, ''));
  let totalLaborCost = laborCost + laborBurden;
  let totalCostWithoutCommission =
    parseFloat(String(workOrderTableObj.materialCost).replace(/,/g, '')) +
    totalLaborCost +
    parseFloat(String(workOrderTableObj.subCost).replace(/,/g, ''));
  let rawGp =
    parseFloat(String(workOrderTableObj.price).replace(/,/g, '')) - totalCostWithoutCommission;
  let commission =
    rawGp *
    (parseFloat(String(workOrderTableObj.commissionPercent).replace(/,/g, '')) /
      (1 + parseFloat(String(workOrderTableObj.commissionPercent).replace(/,/g, ''))));
  let totalGp = rawGp - commission;
  let inHouseGp = totalGp - parseFloat(String(workOrderTableObj.subGp).replace(/,/g, ''));
  let gpmd = 0;
  if (parseFloat(String(workOrderTableObj.manDays).replace(/,/g, '')) > 0) {
    gpmd = inHouseGp / parseFloat(String(workOrderTableObj.manDays).replace(/,/g, ''));
  }

  workOrderTableObj.laborCost = numberWithCommas(totalLaborCost.toFixed(0));
  workOrderTableObj.commissionCost = numberWithCommas(commission.toFixed(0));
  workOrderTableObj.inhouseGp = numberWithCommas(inHouseGp.toFixed(0));
  workOrderTableObj.totalGp = numberWithCommas(totalGp.toFixed(0));
  workOrderTableObj.gpmd = isNaN(gpmd) ? 0 : gpmd.toFixed(0);

  if (state.calculationType === 'price') {
    state.calculationCompleted =
      (parseFloat(workOrderTableObj.gpmd) !== 0 && parseFloat(workOrderTableObj.manDays) !== 0) ||
      (parseFloat(workOrderTableObj.subCost) !== 0 && parseFloat(workOrderTableObj.subGp) !== 0)
        ? true
        : false;
  } else if (state.calculationType === 'manDays') {
    state.calculationCompleted =
      parseFloat(workOrderTableObj.price) === 0 || parseFloat(workOrderTableObj.gpmd) === 0
        ? false
        : true;
  } else if (state.calculationType === 'gpmd') {
    state.calculationCompleted =
      parseFloat(workOrderTableObj.price) === 0 || parseFloat(workOrderTableObj.manDays) === 0
        ? false
        : true;
  }
};
export const calculateWorkOrderTableGpmd3 = (workOrderTableObj, constants, state) => {
  let laborCost =
    8 *
    parseFloat(String(workOrderTableObj.manDays).replace(/,/g, '')) *
    parseFloat(String(workOrderTableObj.laborRate).replace(/,/g, ''));
  let laborBurden =
    laborCost * parseFloat(String(workOrderTableObj.laborBurdenPct).replace(/,/g, ''));
  let totalLaborCost = laborCost + laborBurden;
  let totalCostWithoutCommission =
    parseFloat(String(workOrderTableObj.materialCost).replace(/,/g, '')) +
    totalLaborCost +
    parseFloat(String(workOrderTableObj.subCost).replace(/,/g, ''));
  let rawGp =
    parseFloat(String(workOrderTableObj.price).replace(/,/g, '')) - totalCostWithoutCommission;
  let commission =
    rawGp *
    (parseFloat(String(workOrderTableObj.commisionPct).replace(/,/g, '')) /
      (1 + parseFloat(String(workOrderTableObj.commisionPct).replace(/,/g, ''))));
  let totalGp = rawGp - commission;
  let inHouseGp = totalGp - parseFloat(String(workOrderTableObj.subGp).replace(/,/g, ''));
  let gpmd = 0;
  if (parseFloat(String(workOrderTableObj.manDays).replace(/,/g, '')) > 0) {
    gpmd = inHouseGp / parseFloat(String(workOrderTableObj.manDays).replace(/,/g, ''));
  }
  workOrderTableObj.laborCost = numberWithCommas(totalLaborCost.toFixed(0));
  workOrderTableObj.commissionCost = numberWithCommas(commission.toFixed(0));
  workOrderTableObj.inHouseGp = numberWithCommas(inHouseGp.toFixed(0));
  workOrderTableObj.totalGp = numberWithCommas(totalGp.toFixed(0));
  workOrderTableObj.gpmd = isNaN(gpmd) ? 0 : gpmd.toFixed(0);
  return workOrderTableObj; //Object.assign({}, workOrderTableObj);
};

export const calculatePrice_SplitModule = workOrderState => {
  let materialCost = Number(workOrderState.estimatedMaterialCost);
  let manDays = Number(workOrderState.estimatedManDays);
  let price = Number(workOrderState.estimatedPrice);

  let laborRate = workOrderState.moduleLaborRate;
  let laborBurdenPercent =
    (workOrderState.moduleLaborBurdenPct ? workOrderState.moduleLaborBurdenPct / 100 : 0) * 100;
  let commissionPercent =
    (workOrderState.moduleCommissionPct ? workOrderState.moduleCommissionPct / 100 : 0) * 100;

  let dailyLaborCostOnly = 8 * parseFloat(laborRate);
  let dailyLaborBurden = parseFloat(dailyLaborCostOnly) * parseFloat(laborBurdenPercent);
  let laborCostPerManDay = parseFloat(dailyLaborCostOnly) + parseFloat(dailyLaborBurden);

  let totalLaborCost = parseFloat(laborCostPerManDay) * parseFloat(manDays);
  let rawGp = parseFloat(price) - parseFloat(materialCost) - parseFloat(totalLaborCost);

  let commission =
    parseFloat(rawGp) *
    ((parseFloat(commissionPercent) * 100) / (100 + parseFloat(commissionPercent) * 100));

  let totalGp = parseFloat(rawGp) - parseFloat(commission);
  let inHouseGp = parseFloat(totalGp);

  let gpmd = parseFloat(manDays) === 0 ? 0 : parseFloat(inHouseGp) / parseFloat(manDays);

  workOrderState.estimatedGpmd = gpmd ? gpmd.toFixed(0) : 0;
  workOrderState.estimatedInHouseGp = inHouseGp ? inHouseGp.toFixed(0) : 0;
  workOrderState.estimatedLaborCost = totalLaborCost ? totalLaborCost.toFixed(0) : 0;
  workOrderState.estimatedTotalGp = totalGp ? totalGp.toFixed(0) : 0;
  workOrderState.estimatedCommission = commission ? commission.toFixed(0) : 0;
  return workOrderState;
};

export const calculatSplitModulePrice = (splitModuleDetails, mainModuleDetails) => {
  let splitModuledata = calculatePrice_SplitModule(splitModuleDetails);
  let mainModuleData = calculatePrice_SplitModule(mainModuleDetails);
  return [splitModuledata, mainModuleData];
};
export const calculateProjectedGPMD = workOrderState => {
  let subGp =  Number(workOrderState.subGp);
  let materialCost = Number(workOrderState.materialCost);
  let manDays = Number(workOrderState.projectedManDays);
  let price = Number(workOrderState.price);
  let labourHours = Number(workOrderState.laborWithOtDtHours);
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPCT ? workOrderState.laborBurdenPCT / 100 : 0;
  let commissionPercent = workOrderState.commissionPCT ? workOrderState.commissionPCT / 100 : 0;
  let hourlyLaborBurden = parseFloat(laborRate) * parseFloat(laborBurdenPercent);
  let laborCostPerManHour = parseFloat(laborRate) + parseFloat(hourlyLaborBurden);
  let totalLaborCost =
    parseFloat(laborCostPerManHour) *
    parseFloat(
      workOrderState.projectedManDays * 8 === workOrderState.laborWithOtDtHours
        ? labourHours
        : manDays * 8
    );
  let rawGp = parseFloat(price) - parseFloat(materialCost) - parseFloat(totalLaborCost) - subGp;
  let commission =
    parseFloat(rawGp) *
    ((parseFloat(commissionPercent) * 100) / (100 + parseFloat(commissionPercent) * 100));
  let totalGp = parseFloat(rawGp) - parseFloat(commission);
  let inHouseGp = parseFloat(totalGp);

  let gpmd =
    (parseFloat(manDays) || parseFloat(inHouseGp)) === 0
      ? 0
      : parseFloat(inHouseGp) / parseFloat(manDays);
  workOrderState.gpmd = gpmd ? gpmd.toFixed(0) : 0;
  workOrderState.inHouseGP = inHouseGp ? inHouseGp.toFixed(0) : 0;
  workOrderState.laborCost = totalLaborCost ? totalLaborCost.toFixed(0) : 0;
  workOrderState.totalGP = totalGp ? totalGp.toFixed(0) : 0;
  workOrderState.commissionCost = commission ? commission.toFixed(0) : 0;
  return workOrderState;
};

export const calculateProjected = workOrderState => {
  let materialCost = Number(workOrderState.materialCost);
  let manDays = Number(workOrderState.projectedManDays);
  let price = Number(workOrderState.price);
  let laborRate = workOrderState.laborRate;
  let laborBurdenPercent = workOrderState.laborBurdenPCT ? workOrderState.laborBurdenPCT / 100 : 0;
  let commissionPercent = workOrderState.commissionPCT ? workOrderState.commissionPCT / 100 : 0;
  let dailyLaborCostOnly = 8 * parseFloat(laborRate);
  let dailyLaborBurden = parseFloat(dailyLaborCostOnly) * parseFloat(laborBurdenPercent);
  let laborCostPerManDay = parseFloat(dailyLaborCostOnly) + parseFloat(dailyLaborBurden);
  let totalLaborCost = parseFloat(laborCostPerManDay) * parseFloat(manDays);
  let rawGp = parseFloat(price) - parseFloat(materialCost) - parseFloat(totalLaborCost);
  let commission =
    parseFloat(rawGp) *
    ((parseFloat(commissionPercent) * 100) / (100 + parseFloat(commissionPercent) * 100));
  let totalGp =
    (parseFloat(workOrderState.estimatetotalgp) * parseFloat(manDays)) /
    workOrderState.estimatemandays;
  let inHouseGp = parseFloat(totalGp);

  let gpmd =
    (parseFloat(manDays) || parseFloat(inHouseGp)) === 0
      ? 0
      : parseFloat(inHouseGp) / parseFloat(manDays);
  workOrderState.gpmd = gpmd ? gpmd.toFixed(0) : 0;
  workOrderState.inHouseGP = inHouseGp ? inHouseGp.toFixed(0) : 0;
  workOrderState.laborCost = totalLaborCost ? totalLaborCost.toFixed(0) : 0;
  workOrderState.totalGP = totalGp ? totalGp.toFixed(0) : 0;
  workOrderState.rawGp = rawGp ? rawGp.toFixed(0) : 0;

  workOrderState.commissionCost = commission ? commission.toFixed(0) : 0;
  return workOrderState;
};

export const getDateAndDayFromDate = (startDate, increment) => {
  var days = ['Sun', 'Mon ', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  var newDate = new Date(moment(startDate).format('YYYY/MM/DD'));
  if (increment == 0) {
    var dayName = days[newDate.getDay()];
    return dayName + '  ' + moment(newDate).format('MM/D');
  } else {
    newDate.setDate(newDate.getDate() + increment);
    var dayName = days[newDate.getDay()];
    return dayName + '   ' + moment(newDate).format('MM/D');
  }
};
export const getDateAndDayFromDate_UsingMomentjs = (startDate, increment) => {
  var days = ['Sunday', 'Monday ', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var newDate = new Date(moment(startDate).format('YYYY-MM-DD'));
  if (increment == 0) {
    var dayName = days[newDate.getDay()];
    return dayName + '<br/>' + moment(newDate).format('YYYY-MM-DD');
  } else {
    newDate.setDate(newDate.getDate() + increment);
    var dayName = days[newDate.getDay()];
    return dayName + '<br/>' + moment(newDate).format('YYYY-MM-DD');
  }
};
