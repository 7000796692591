import {
  checkCompanyInfoCompleted as checkComInfoCompleted,
  getStates,
  getPhoneTypesList as getPhoneTypes,
  getAimmLiteWorkTypeList,
  getJobTypesList,
  getAllContractorTypes,
  getSubNonSubList,
  getCustomerInfo,
  getJobInfo,
  getSystemSettings,
} from 'clients/api';
//cookies
import Cookies from 'js-cookie';

export const getCompanySystemSettings = IdFromHeaders => async dispatch => {
  const res = await getSystemSettings({ companyId: Cookies.get('companyId') || IdFromHeaders });
  dispatch({ type: 'SETSTARTENDWEEKSETTINGS', payload: res.data });
};

export const setConvertWidgetStatus = value => async dispatch => {
  dispatch({ type: 'SETCONVERTWIDGETVALUESTATUS', payload: value });
};

export const convertWigetValuestoEstimate = value => async dispatch => {
  dispatch({ type: 'CONVERTWIDGETVALUESTOESTIMATE', payload: value });
};

export const generateCogsTab = data => ({
  type: 'GENERATECOGSTAB',
  payload: data,
});
export const generatePnLObjects = data => dispatch => {
  dispatch({ type: 'GENERATEPNLOBJECTS', payload: data });
  return { type: 'GENERATEPNLOBJECTS', payload: data };
};
export const resetSiteCommon = () => ({
  type: 'RESETSITECOMMON',
});
export const changeConstant = (key, value, type) => ({
  type: 'CHANGECONSTANT',
  payload: { key, value, type },
});
export const setConstant = (key, value, type) => ({
  type: 'SETCONSTANT',
  payload: { key, value, type },
});

export const checkIfCompanyInfoCompleted = () => async dispatch => {
  try {
    const response = await checkComInfoCompleted({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({
        type: 'SETCOMPANYINFOSTATUS',
        payload: response.data.isCompanyInfoCompleted,
      });
      return {
        type: 'SETCOMPANYINFOSTATUS',
        payload: response.data.isCompanyInfoCompleted,
      };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getStateList = () => async dispatch => {
  try {
    const response = await getStates({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({ type: 'SETSTATELIST', payload: response.data });
      return { type: 'SETSTATELIST', payload: response.data };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getPhoneTypeList = () => async dispatch => {
  try {
    const response = await getPhoneTypes({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({ type: 'SETPHONETYPELIST', payload: response.data });
      return { type: 'SETPHONETYPELIST', payload: response.data };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getWorkTypeList = () => async dispatch => {
  try {
    const response = await getAimmLiteWorkTypeList({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({ type: 'SETWORTYPELIST', payload: response.data });
      return { type: 'SETWORKTYPELIST', payload: response.data };
    } else {
      console.log('ERROR:: ');
      return { type: 'ERROR' };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getJobTypeList = () => async dispatch => {
  try {
    const response = await getJobTypesList({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({ type: 'SETJOBTYPELIST', payload: response.data });
      return { type: 'SETJOBTYPELIST', payload: response.data };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getContractorType = () => async dispatch => {
  try {
    const response = await getAllContractorTypes({ companyId: Cookies.get('companyId') });
    if (response.data) {
      dispatch({ type: 'SETCONTRACTORTYPE', payload: response.data });
      return { type: 'SETCONTRACTORTYPE', payload: response.data };
    }
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const updateTab = payloaddata => ({
  type: 'UPDATETAB',
  payload: payloaddata,
});

export const getConstants = () => dispatch => {
  /*return axios.get(`${config.apiURL}${Cookies.get('companyId')}/setting/GetSystemSettingsForEstimateCalculator` )
  .then(response => { 
      if (response.data) {
          dispatch({type: 'GETCONSTANTS', payload: response.data});
          return ({ type: 'GETCONSTANTS', payload: response.data });
      }
  })
  .catch(error => {
    console.log("ERROR:: ", error);
    return ({ type: 'ERROR' });
  });*/
  dispatch({
    type: 'GETCONSTANTS',
    payload: [{ value: 0 }, { value: 0 }, { value: 0 }],
  });
  return {
    type: 'GETCONSTANTS',
    payload: [{ value: 0 }, { value: 0 }, { value: 0 }],
  };
};

export const checkSubscontractorSourceList = isSubcontractorIncomeSource => async dispatch => {
  try {
    const response = await getSubNonSubList({
      companyId: Cookies.get('companyId'),
      tOrF: isSubcontractorIncomeSource,
    });
    response.data.subType = isSubcontractorIncomeSource;
    return dispatch({ type: 'CONTRACTORSOURCE', payload: response.data });
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const selectCustomerEstimator = cusId => async dispatch => {
  try {
    const response = await getCustomerInfo({ companyId: Cookies.get('companyId'), userId: cusId });
    dispatch({ type: 'SETCUSTOMERIDGPMDESTIMATOR', payload: cusId });
    dispatch({ type: 'SETCUSTOMERINFOESTIMATOR', payload: response.data });
    return { type: 'SETCUSTOMERINFOESTIMATOR', payload: response.data };
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const getJobDetailsEstimator = jobId => async dispatch => {
  try {
    // customer job INFO
    const response = await getJobInfo({ companyId: Cookies.get('companyId'), jobId });
    dispatch({
      type: 'SETCUSTOMERJOBINFOESTIMATOR',
      payload: response.data,
    });
    return { type: 'SETCUSTOMERJOBINFOESTIMATOR', payload: response.data };
  } catch (error) {
    return { type: 'ERROR' };
  }
};

export const selectJobEstimator = jobId => ({
  type: 'SETJOBIDGPMDESTIMATOR',
  payload: jobId,
});

export const selectWorkOrderEstimator = workOrderId => ({
  type: 'SETWORKORDERIDGPMDESTIMATOR',
  payload: workOrderId,
});

export const changeCommonEstimator = () => ({
  type: 'ESTIMATOR',
});

export const setEstimatedTableName = data => ({
  type: 'SETESTIMATEDTABLENAME',
  payload: data,
});

export const resetEstimatedTableName = () => ({
  type: 'RESETESTIMATEDTABLENAME',
});

export const resetGpmdEstimatorForm = () => ({
  type: 'RESETGPMDESTIMATORFORM',
});

export const resetWorkOrderId = () => ({
  type: 'RESETWORKORDERID',
});

export const resetJobId = () => ({
  type: 'RESETJOBID',
});

export const changeEstimateOnlyEstimator = () => ({
  type: 'SETESTIMATEONLYESTIMATOR',
});
