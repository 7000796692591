import { numberWithCommas, calculateWorkOrderTableGpmd2 } from 'utils';
const initialState = {
  addNewCustomer: false,
  editCustomer: false,
  addNewJob: false,
  editJob: false,
  searchCustomer: false,
  addWorkOrder: false,
  editWorkOrder: false,
  startFieldChangingCustomer: false,
  startFieldChangingJob: false,
  startFieldChangingWorkOrder: false,
  calculationType: 'price',
  calculationCompleted: false,
  convertToSub: false,
  calculationChanged: false,
  _isDoEstimateOnly: false,
  income: {
    selected: '',
  },
  customer: {
    info: null,
    customerList: [],
    form: {
      customerDisplayName: '',
      customerType: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      phone1: '',
      phone1TypeId: '',
    },
  },
  job: {
    _isArchived: false,
    selected: null,
    totalPrice: '',
    estGp: '',
    estGpmd: '',
    type: '',
    info: {},
    form: {
      jobName: '',
      jobTypeId: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      addressId: '',
      shortAddress: '',
      rowVersion: '',
      strRowVersionAddress: '',
      rowVersionAddress: '',
    },
    jobList: [],
    jobTableData: {},
  },
  workOrder: {
    selected: 0,
    name: '',
    wonumber: '',
    description: '',
    workOrderType: '',
    workOrderList: [],
    workOrderTableData: {
      id: '1',
      price: '0',
      materialCost: '0',
      manDays: '0.000',
      laborCost: '0',
      subCost: '0',
      commissionCost: '0',
      inhouseGp: '0',
      subGp: '0',
      totalGp: '0',
      gpmd: '0',
    },
    info: {},
  },
};

let nextState = null;

const gpmdReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RESETGPMD':
      return initialState;
    case 'CHANGEONESTIMATEGPMD':
      if (action.payload !== undefined) {
        return { ...state, _isDoEstimateOnly: action.payload };
      } else {
        return { ...state, _isDoEstimateOnly: !state._isDoEstimateOnly };
      }
    case 'CONVERTTOSUB':
      return { ...state, convertToSub: true };
    case 'CONVERTTOSUBRESET':
      return { ...state, convertToSub: false };
    case 'GENERATECONVERTOSUBS':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state

      nextState.workOrder.info.price = numberWithCommas(
        parseFloat(action.payload.price).toFixed(0)
      );
      nextState.workOrder.info.manDays = numberWithCommas(
        parseFloat(action.payload.manDays).toFixed(0)
      );
      nextState.workOrder.info.materialCost = parseFloat(action.payload.materialCost).toFixed(0);
      nextState.workOrder.info.subCost = parseFloat(action.payload.subCost).toFixed(0);
      nextState.workOrder.info.laborCost = parseFloat(action.payload.laborCost).toFixed(0);
      nextState.workOrder.info.totalGp = numberWithCommas(
        parseFloat(action.payload.totalGp).toFixed(0)
      );
      nextState.workOrder.info.commissionPercent = numberWithCommas(
        parseFloat(action.payload.commission).toFixed(0)
      );
      nextState.workOrder.info.subGp = parseFloat(action.payload.subGp).toFixed(0);
      nextState.workOrder.info.gpmd = numberWithCommas(parseFloat(action.payload.gpmd).toFixed(0));
      nextState.workOrder.info.inhouseGp = parseFloat(action.payload.inhouseGp).toFixed(0);

      return nextState;
    case 'SETJOBARCHIVEDORNOT':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job._isArchived = !state.job._isArchived;
      return nextState;
    case 'SETCUSTOMERLIST':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.customer.customerList = action.payload;
      return nextState;
    case 'SETCUSTOMERINFO':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.customer.info = action.payload;
      return nextState;
    case 'SETCUSTOMERJOBINFO':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.info = action.payload;
      return nextState;
    case 'SETWORKORDERINFOGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      let newWorkOrderObj = {
        ...action.payload.data,
        id: '1',
        laborCost: 0,
        commissionCost: 0,
        inhouseGp: 0,
        totalGp: 0,
        gpmd: 0,
      };
      nextState.workOrder.info = newWorkOrderObj;
      calculateWorkOrderTableGpmd2(nextState.workOrder.info, action.payload.constants, nextState);
      return nextState;
    case 'SELECTCUSTOMERGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.customer.name = action.payload;
      return nextState;
    case 'SETCUSTOMERJOBLIST':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.jobList = action.payload;
      return nextState;
    case 'SETCUSTOMERJOBGPMDBAR':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (action.payload.aimmLiteJobId === 0) {
        nextState.job.jobTableData = {};
      } else {
        let newObj = { ...action.payload };
        nextState.job.jobTableData = newObj;
        nextState.job.jobTableData.materialCost = nextState.job.jobTableData.materialCost.toFixed(
          0
        );
        nextState.job.jobTableData.price = nextState.job.jobTableData.price.toFixed(0);
        nextState.job.jobTableData.manDays = nextState.job.jobTableData.manDays.toFixed(3);
        nextState.job.jobTableData.laborCost = nextState.job.jobTableData.laborCost.toFixed(0);
        nextState.job.jobTableData.subCost = nextState.job.jobTableData.subCost.toFixed(0);
        nextState.job.jobTableData.commissionCost = nextState.job.jobTableData.commissionCost.toFixed(
          0
        );
        nextState.job.jobTableData.inHouseGP = nextState.job.jobTableData.inHouseGP.toFixed(0);
        nextState.job.jobTableData.subGp = nextState.job.jobTableData.subGp.toFixed(0);
        nextState.job.jobTableData.totalGP = nextState.job.jobTableData.totalGP.toFixed(0);
        nextState.job.jobTableData.gpmd = nextState.job.jobTableData.gpmd.toFixed(0);
      }

      return nextState;
    case 'SELECTJOBGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.selected = action.payload !== null ? parseInt(action.payload) : null;
      return nextState;
    case 'SELECTINCOMESOURCEGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.income.selected = action.payload !== null ? parseInt(action.payload) : null;
      nextState.calculationCompleted = false;
      return nextState;
    case 'SETWORKORDERLIST':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder.workOrderList = action.payload;
      return nextState;
    case 'SELECTWORKORDERGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder.selected = action.payload !== null ? parseInt(action.payload) : null;
      return nextState;
    case 'CLEARSELECTEDWORKORDER':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder.selected = null;
      nextState.workOrder.info = {};
      return nextState;
    case 'CLEARSELECTEDJOB':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.selected = null;
      nextState.job.info = {};
      return nextState;
    case 'CLEARJOBLIST':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.jobList = [];
      nextState.job.jobTableData = {};
      return nextState;
    case 'CLEARJOBDETAILSGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.jobTableData = {};
      nextState.job.info = {};
      return nextState;
    case 'CLEARWORKORDERLIST':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder.workOrderList = [];
      nextState.workOrder.info = {};
      return nextState;
    case 'CLEARSELECTEDCUSTOMER':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.customer.info = null;
      return nextState;
    case 'SELECTWORKTYPEGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder.workOrderType = action.payload !== null ? parseInt(action.payload) : null;
      return nextState;
    case 'CALCULATEPRICEGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (!action.payload.doEstimate) {
        nextState.workOrder.info.laborCost = numberWithCommas(
          (((action.payload.totalLaborCost + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.inhouseGp = numberWithCommas(action.payload.inHouseGp.toFixed(0));
        nextState.workOrder.info.totalGp = numberWithCommas(action.payload.totalGp.toFixed(0));
        nextState.workOrder.info.price = numberWithCommas(
          (((action.payload.price + 0.00001) * 100) / 100).toFixed(0)
        );
      } else {
        nextState.workOrder.workOrderTableData.laborCost = numberWithCommas(
          (((action.payload.totalLaborCost + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.inhouseGp = numberWithCommas(
          action.payload.inHouseGp.toFixed(0)
        );
        nextState.workOrder.workOrderTableData.totalGp = numberWithCommas(
          action.payload.totalGp.toFixed(0)
        );
        nextState.workOrder.workOrderTableData.price = numberWithCommas(
          (((action.payload.price + 0.00001) * 100) / 100).toFixed(0)
        );
      }

      return nextState;
    case 'CALCULATEMANDAYSGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (!action.payload.doEstimate) {
        nextState.workOrder.info.laborCost = numberWithCommas(
          (((action.payload.laborCostOnly + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.inhouseGp = numberWithCommas(
          (((action.payload.inHouseGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.totalGp = numberWithCommas(
          (((action.payload.totalGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.manDays = numberWithCommas(
          (((action.payload.manDays + 0.00001) * 100) / 100).toFixed(3)
        );
      } else {
        nextState.workOrder.workOrderTableData.laborCost = numberWithCommas(
          (((action.payload.laborCostOnly + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.inhouseGp = numberWithCommas(
          (((action.payload.inHouseGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.totalGp = numberWithCommas(
          (((action.payload.totalGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.manDays = numberWithCommas(
          (((action.payload.manDays + 0.00001) * 100) / 100).toFixed(3)
        );
      }

      return nextState;
    case 'CALCULATEGPMDGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (!action.payload.doEstimate) {
        nextState.workOrder.info.laborCost = numberWithCommas(
          (((action.payload.totalLaborCost + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.inhouseGp = numberWithCommas(
          (((action.payload.inHouseGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.totalGp = numberWithCommas(
          (((action.payload.totalGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.info.gpmd = numberWithCommas(
          (((action.payload.gpmd + 0.00001) * 100) / 100).toFixed(0)
        );
      } else {
        nextState.workOrder.workOrderTableData.laborCost = numberWithCommas(
          (((action.payload.totalLaborCost + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.commissionCost = numberWithCommas(
          (((action.payload.commission + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.inhouseGp = numberWithCommas(
          (((action.payload.inHouseGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.totalGp = numberWithCommas(
          (((action.payload.totalGp + 0.00001) * 100) / 100).toFixed(0)
        );
        nextState.workOrder.workOrderTableData.gpmd = numberWithCommas(
          (((action.payload.gpmd + 0.00001) * 100) / 100).toFixed(0)
        );
      }

      return nextState;
    case 'EDITCUSTOMERGPMD':
      return {
        ...state,
        editCustomer: true,
        addNewCustomer: false,
      };
    case 'STOPEDITCUSTOMERGPMD':
      return {
        ...state,
        editCustomer: false,
        addNewCustomer: false,
      };
    case 'STOPEDITJOBGPMD':
      return {
        ...state,
        editJob: false,
        addNewJob: false,
      };
    case 'STOPEDITWORKORDER':
      return {
        ...state,
        editWorkOrder: false,
        addWorkOrder: false,
      };
    case 'EDITJOBSTATUSGPMD':
      return {
        ...state,
        editJob: true,
        addNewJob: false,
      };
    case 'ADDJOBSTATUSGPMD':
      return {
        ...state,
        editJob: false,
        addNewJob: true,
      };
    case 'ADDWORKORDERSTATUSGPMD':
      return {
        ...state,
        addWorkOrder: true,
        editWorkOrder: false,
      };
    case 'EDITWORKORDERSTATUSGPMD':
      return {
        ...state,
        editWorkOrder: true,
        addWorkOrder: false,
      };
    case 'ADDCUSTOMERGPMD':
      return {
        ...state,
        addNewCustomer: true,
        editCustomer: false,
      };
    case 'SEARCHCUSTOMER':
      return {
        ...state,
        searchCustomer: !state.searchCustomer,
      };
    case 'STARTFIELDCHANGINGCUSTOMERGPMD':
      return {
        ...state,
        startFieldChangingCustomer: true,
      };
    case 'STARTFIELDCHANGINGWORKORDERGPMD':
      return {
        ...state,
        startFieldChangingWorkOrder: true,
      };
    case 'STOPFIELDCHANGINGWORKORDERGPMD':
      return {
        ...state,
        startFieldChangingWorkOrder: false,
      };
    case 'STARTFIELDCHANGINGJOBGPMD':
      return {
        ...state,
        startFieldChangingJob: true,
      };
    case 'STOPFIELDCHANGINGJOBGPMD':
      return {
        ...state,
        startFieldChangingJob: false,
      };
    case 'STOPFIELDCHANGINGCUSTOMERGPMD':
      return {
        ...state,
        startFieldChangingCustomer: false,
      };
    case 'RESETFIELDCUSTOMERGPMD':
      return {
        ...state,
        startFieldChangingCustomer: false,
      };
    case 'CHANGECUSTOMERFIELDGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.customer.form[action.payload.key] = action.payload.value;
      return nextState;
    case 'CHANGEJOBFIELDGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.job.form[action.payload.key] = action.payload.value;
      return nextState;
    case 'CHANGEWORKORDERFIELDGPMD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      nextState.workOrder[action.payload.key] = action.payload.value;
      return nextState;
    case 'CLEARWORKORDERTABLEFIELDS':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state

      const tableFields = [
        'price',
        'materialCost',
        'manDays',
        'laborCost',
        'subCost',
        'commissionCost',
        'inhouseGp',
        'subGp',
        'totalGp',
        'gpmd',
      ];

      if (!action.payload.doEstimate) {
        for (let key in nextState.workOrder.info) {
          if (tableFields.includes(key)) {
            nextState.workOrder.info[key] = 0;
          }
        }
      } else {
        for (let key in nextState.workOrder.workOrderTableData) {
          if (tableFields.includes(key)) {
            nextState.workOrder.workOrderTableData[key] = 0;
          }
        }
      }

      nextState.calculationCompleted = false;
      return nextState;
    case 'EDITWORKORDERTABLEFIELD':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (!action.payload.doEstimate) {
        if (action.payload.value === '') {
          nextState.workOrder.info[action.payload.key] = 0;
        } else {
          nextState.workOrder.info[action.payload.key] = action.payload.value;
        }
      } else {
        if (action.payload.value === '') {
          nextState.workOrder.workOrderTableData[action.payload.key] = 0;
        } else {
          nextState.workOrder.workOrderTableData[action.payload.key] = action.payload.value;
        }
      }

      return nextState;
    case 'MAPWORKORDERTABLEFIELDS':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (!action.payload.doEstimate) {
        nextState.workOrder.info = {
          ...nextState.workOrder.info,
          ...action.payload.obj,
        };
      } else {
        nextState.workOrder.workOrderTableData = {
          ...nextState.workOrder.workOrderTableData,
          ...action.payload.obj,
        };
      }

      return nextState;
    case 'EDITWORKORDERTABLEFIELDBATCH':
      nextState = JSON.parse(JSON.stringify(state)); // deep copying state
      if (action.payload.length) {
        action.payload.forEach(item => {
          nextState.workOrder.info[item.key] = item.value;
        });
      }
      return nextState;
    case 'RESETGPMDCALCULATION':
      return {
        ...state,
        calculationCompleted: false,
        calculationChanged: false,
      };
    case 'CHANGECALCULATIONTYPEGPMD':
      return { ...state, calculationType: action.payload };
    case 'CALCULATIONCOMPLETED':
      return {
        ...state,
        calculationCompleted: true,
        calculationChanged: false,
      };
    case 'CALCULATIONCHANGEDGPMD':
      return {
        ...state,
        calculationChanged: true,
        calculationCompleted: false,
      };
    default:
      return state;
  }
};

export default gpmdReducer;
