const authReducer = (state = { isLoggedIn: false }, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...action.payload, isLoggedIn: true };
    case 'LOGOUT':
      return { isLoggedIn: false };
    case 'SET_USER':
      return { ...state, isLoggedIn: true, user: action.payload };
    case 'REMOVEUSER':
      return { isLoggedIn: false };
    default:
      return state;
  }
};

export default authReducer;
