import { toastr } from 'utils';
import { getCompany } from './companyInfoActions';
import Cookies from 'js-cookie';
import {
  loginWithPassword,
  getUserData,
  getCustomersGridList,
  checkCompanyInfoCompleted,
  getCompanyIncomeSourceList,
  getCompanyIncomeSourceInHouseEmployeeList,
  getCompanyIncomeSourceInHouseCogsInfo,
} from 'clients/api';
//import actions
import { getInHouseCogsListPL, getConstantsList } from './plSummeryActions';
import { getCompanySystemSettings } from './commonActions';

export const attemptLogin = ({ username, password, company }, cb) => async dispatch => {
  try {
    const loginRes = await loginWithPassword({ company, username, password });
    const obj = {
      firstName: null,
      lastName: null,
    };
    Cookies.set('token', loginRes.data.access_token);
    Cookies.set('username', username);
    Cookies.set('companyId', loginRes.headers['aimm-companyid']);

    dispatch({
      type: 'LOGIN_SUCCESS',
      payload: { username },
    });
    const loginDate = new Date();
    localStorage.setItem('loginDate', loginDate.toString());
    const userRes = await getUserData({
      companyId: Cookies.get('companyId'),
      username,
    });
    if (userRes.data && userRes.data.hasAimmLiteFeature) {
      dispatch({ type: 'SET_USER', payload: userRes.data });
    }
    dispatch(getCompany());
    dispatch(getInHouseCogsListPL(loginRes.headers['aimm-companyid']));
    dispatch(getConstantsList(loginRes.headers['aimm-companyid']));
    dispatch(getCompanySystemSettings(loginRes.headers['aimm-companyid']));
    const companyCompletedCheckResult = await checkCompanyInfoCompleted({
      companyId: Cookies.get('companyId'),
    });
    const companyIncomeSourceList = await getCompanyIncomeSourceList({
      companyId: Cookies.get('companyId'),
    });
    const companyIncomeSourceInHouseEmployeeList = await getCompanyIncomeSourceInHouseEmployeeList({
      companyId: Cookies.get('companyId'),
    });

    //get income Source Cogs List
    let incomeSourceCogs = [];
    if (companyIncomeSourceList.data.length > 0) {
      const incomeSourceCogsPromises = companyIncomeSourceList.data.map(
        async ({ companyIncomeSourceId }) => {
          return await getCompanyIncomeSourceInHouseCogsInfo({
            companyId: Cookies.get('companyId'),
            number: companyIncomeSourceId,
          });
        }
      );
      const incomeSourceCogsResult = await Promise.all(incomeSourceCogsPromises);
      if (incomeSourceCogsResult && incomeSourceCogsResult.length > 0) {
        incomeSourceCogsResult.map(e => {
          incomeSourceCogs.push(e.data);
        });
      }
    }
    dispatch({ type: 'GETSOURCECOGS', payload: incomeSourceCogs });

    dispatch({ type: 'GETSOURCELIST', payload: companyIncomeSourceList.data });
    dispatch({
      type: 'SETINHOUSEEMPLOYEELISTS',
      payload: companyIncomeSourceInHouseEmployeeList.data,
    });
    dispatch({ type: 'GETSOURCELIST', payload: companyIncomeSourceList.data });
    dispatch({
      type: 'SETCOMPANYINFOSTATUS',
      payload: companyCompletedCheckResult.data.isCompanyInfoCompleted,
    });

    cb();
    toastr.success('Successfully logged in!');
  } catch (error) {
    console.log('error-login', error);
    toastr.error('Login failed!');
  }
};

export const loginCheck = () => async dispatch => {
  try {
    const userRes = await getUserData({
      companyId: Cookies.get('companyId'),
      username: Cookies.get('username'),
    });
    const obj = {
      firstName: null,
      lastName: null,
    };

    const loginDate = localStorage.getItem('loginDate');
    if (!!loginDate) {
      const diffTime = Math.abs(new Date() - new Date(loginDate));
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffDays >= 30) throw { message: 'Login Token Expired' };
    }

    if (userRes.data && userRes.data.hasAimmLiteFeature) {
      dispatch(getCompany());
      dispatch(getInHouseCogsListPL());
      dispatch(getConstantsList());
      dispatch(getCompanySystemSettings());
      dispatch({ type: 'SET_USER', payload: userRes.data });
      const companyCompletedCheckResult = await checkCompanyInfoCompleted({
        companyId: Cookies.get('companyId'),
      });
      // const companyIncomeSourceInHouseEmployeeList = await getCompanyIncomeSourceInHouseEmployeeList(
      //   {
      //     companyId: Cookies.get('companyId'),
      //   }
      // );
      // dispatch({
      //   type: 'SETINHOUSEEMPLOYEELISTS',
      //   payload: companyIncomeSourceInHouseEmployeeList.data,
      // });
      const companyIncomeSourceList = await getCompanyIncomeSourceList({
        companyId: Cookies.get('companyId'),
      });

      //get income Source Cogs List
      let incomeSourceCogs = [];
      if (companyIncomeSourceList.data.length > 0) {
        const incomeSourceCogsPromises = companyIncomeSourceList.data.map(
          async ({ companyIncomeSourceId }) => {
            return await getCompanyIncomeSourceInHouseCogsInfo({
              companyId: Cookies.get('companyId'),
              number: companyIncomeSourceId,
            });
          }
        );
        const incomeSourceCogsResult = await Promise.all(incomeSourceCogsPromises);
        if (incomeSourceCogsResult && incomeSourceCogsResult.length > 0) {
          incomeSourceCogsResult.map(e => {
            incomeSourceCogs.push(e.data);
          });
        }
      }
      dispatch({ type: 'GETSOURCECOGS', payload: incomeSourceCogs });

      dispatch({ type: 'GETSOURCELIST', payload: companyIncomeSourceList.data });

      dispatch({
        type: 'SETCOMPANYINFOSTATUS',
        payload: companyCompletedCheckResult.data.isCompanyInfoCompleted,
      });
    }
  } catch (error) {
    console.log('Error when get user data', error);
    Cookies.remove('token');
    Cookies.remove('username');
    Cookies.remove('companyId');
    window.location.replace('/login');
  }
};

export const onLogout = () => ({
  type: 'LOGOUT',
});
