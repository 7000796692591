import { get, put, del, post } from './apiCore';

/**
 * Additional Expenses
 */
export function getComAdditionalExpense(queryParams) {
  return get(`${queryParams.companyId}/companyAddlExpense`);
}
export function addComAdditionalExpense(queryParams, obj) {
  return post(`${queryParams.companyId}/companyAddlExpense`, obj);
}
export function updateComAdditionalExpense(queryParams, obj) {
  return put(`${queryParams.companyId}/companyAddlExpense/${queryParams.companyId}`, obj);
}

export function getAddlExpenseBackBillById(queryParams) {
  return get(`${queryParams.companyId}/companyAddlExpenseBackBill/${queryParams.id}`);
}
// Get all backbill for that company
export function getAllBackBills(queryParams) {
  return get(`${queryParams.companyId}/companyAddlExpenseBackBill/all`);
}
export function addAddlExpenseBackBill(queryParams, obj) {
  return post(`${queryParams.companyId}/companyAddlExpenseBackBill`, obj);
}
export function updateAddlExpenseBackBill(queryParams, obj) {
  return put(`${queryParams.companyId}/companyAddlExpenseBackBill/${queryParams.id}`, obj);
}
export function delAddlExpenseBackBill(queryParams) {
  return del(`${queryParams.companyId}/companyAddlExpenseBackBill/${queryParams.id}`);
}

export function getAllComExpenseVehicles(queryParams) {
  return get(`${queryParams.companyId}/companyAddlExpenseVehReplace/all`);
}
export function addComExpenseVehicle(queryParams, obj) {
  return post(`${queryParams.companyId}/companyAddlExpenseVehReplace`, obj);
}
export function updateComExpenseVehicle(queryParams, obj) {
  return put(`${queryParams.companyId}/companyAddlExpenseVehReplace/${queryParams.id}`, obj);
}
export function delComExpenseVehicle(queryParams) {
  return del(`${queryParams.companyId}/companyAddlExpenseVehReplace/${queryParams.id}`);
}

// System settings
export function getSystemSettings(queryParams) {
  return get(`${queryParams.companyId}/setting/getSystemSettings`);
}
export function updateSystemSettings(queryParams, obj) {
  return put(`${queryParams.companyId}/setting`, obj);
}

/**
 * User APIs
 */
//POST FUNCS

export function dltUserData(queryParams) {
  return del(`${queryParams.companyId}/user/${queryParams.aimmUserId}`);
}
export function updateUserData(queryParams) {
  return put(`${queryParams.companyId}/user/${queryParams.aimmUserId}`, queryParams);
}
export function getUserData(queryParams) {
  return get(`${queryParams.companyId}/user/${queryParams.username}`);
}
export function getCustomerInfo(queryParams) {
  return get(`${queryParams.companyId}/customer/getCustomerInfo/${queryParams.userId}`);
}
export function getCustomerEstimates(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimate/getCustomerEstimates/${queryParams.customerId}`
  );
}
export function getApiCustomerJobs(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getCustomerJobs/${queryParams.customerId}`);
}
export function getCustomerWorkOrders(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getCustomerWorkOrders/${queryParams.customerId}`
  );
}
export function getCustomersList(queryParams) {
  return get(`${queryParams.companyId}/customer/getCustomersList`);
}
export function getSubNonSubList(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSource/getSubNonSubContractorIncomeSourceList/${queryParams.tOrF}`
  );
}

/**
 * State, Phone APIs
 */
export function getStates(queryParams) {
  return get(`${queryParams.companyId}/lookup/states`);
}
export function getPhoneTypesList(queryParams) {
  return get(`${queryParams.companyId}/phoneType/getPhoneTypesList`);
}
export function getAllContractorTypes(queryParams) {
  return get(`${queryParams.companyId}/contractorType/all`);
}
export function getAimmLiteWorkTypeList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteWorkType/getAimmLiteWorkTypeList`);
}

/**
 * Company APIs
 */
export function getCompanyInfo(queryParams) {
  return get(`${queryParams.companyId}/company/getCompanyInfo`);
}
export function getCompanyUsers(queryParams) {
  return get(`${queryParams.companyId}/user/allUser?includeAll=true`);
}
export function getCompanyIncomeSourceList(queryParams) {
  return get(`${queryParams.companyId}/companyIncomeSource/getCompanyIncomeSourceList`);
}
export function getCompanyOverheadSummaryInfo(queryParams) {
  return get(`${queryParams.companyId}/companyOverheadSummary/getCompanyOverheadSummaryInfo`);
}
export function getCompanyOverheadAdminInfo(queryParams) {
  return get(`${queryParams.companyId}/companyOverheadAdmin/getCompanyOverheadAdminInfo`);
}
export function getCompanyOverheadInsuranceInfo(queryParams) {
  return get(`${queryParams.companyId}/companyOverheadInsurance/getCompanyOverheadInsuranceInfo`);
}
export function getCompanyOverheadMarketingList(queryParams) {
  return get(`${queryParams.companyId}/companyOverheadMarketing/getCompanyOverheadMarketingList`);
}
export function getCompanyOverheadSmallToolsInfo(queryParams) {
  return get(`${queryParams.companyId}/companyOverheadSmallTools/getCompanyOverheadSmallToolsInfo`);
}
export function getCompanyOverheadTrucksAndEquipmentList(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadTrucksAndEquipment/getCompanyOverheadTrucksAndEquipmentList`
  );
}
export function getCompanyOverheadGeneralOfficeList(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadGeneralOffice/getCompanyOverheadGeneralOfficeList`
  );
}
export function checkCompanyInfoCompleted(queryParams) {
  return get(`${queryParams.companyId}/company/checkCompanyInfoCompleted`);
}
export function getCompanyOverheadAdminEmployeeList(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadAdminEmployee/getCompanyOverheadAdminEmployeeList/${queryParams.salaried}`
  );
}
export function getCompanyIncomeSourceInHouseEmployeeList(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseEmployee/getCompanyIncomeSourceInHouseEmployeeList`
  );
}
export function getCompanyIncomeSourceInHouseCogsInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseCogs/getCompanyIncomeSourceInHouseCogsInfo/${queryParams.number}`
  );
}
export function getConstants(queryParams) {
  return get(`${queryParams.companyId}/companyIncomeSourceInHouseCogs/getConstants`);
}
export function getCompanyIncomeSourceSubListPL(queryParams) {
  return get(`${queryParams.companyId}/companyIncomeSourceSub/getCompanyIncomeSourceSubListPL`);
}
export function getCompanyOverheadSmallToolsPersonnelCount(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadSmallTools/getCompanyOverheadSmallToolsPersonnelCount`
  );
}
export function getCompanyIncomeSourceInHouseCogsList(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseCogs/getCompanyIncomeSourceInHouseCogsList`
  );
}
export function getCompanyIncomeSourceDetailListPL(queryParams) {
  return get(`${queryParams.companyId}/companyIncomeSource/getCompanyIncomeSourceDetailListPL`);
}
export function getGpmdCalculationConstantsSetting(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseCogs/getGpmdCalculationConstantsSetting/${queryParams.number}/${queryParams.isSubcontractor}`
  );
}
export function getCompanyIncomeSourceFullInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSource/getCompanyIncomeSourceFullInfo/${queryParams.incomeSrcId}`
  );
}
export function getCompanyOverheadMarketingInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadMarketing/getCompanyOverheadMarketingInfo/${queryParams.companyOverheadMarketingId}`
  );
}
export function getCompanyIncomeSourceInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSource/getCompanyIncomeSourceInfo/${queryParams.sourceId}`
  );
}
export function getComOverheadTrucksAndEquipmentInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadTrucksAndEquipment/getCompanyOverheadTrucksAndEquipmentInfo/${queryParams.id}`
  );
}

/**
 * JOB APIs
 */

// GET
export function getJobTypesList(queryParams) {
  return get(`${queryParams.companyId}/jobType/getJobTypesList`);
}
export function getJobInfo(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getJobInfo/${queryParams.jobId}`);
}
export function getWorkOrderCalculationBasedOnJob(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJobWorkOrder/getWorkOrderCalculationBasedOnJob/${queryParams.jobId}`
  );
}
export function getJobWorkOrderNamesList(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJobWorkOrder/getJobWorkOrderNamesList/${queryParams.jobId}`
  );
}

export function getJobWorkOrderNamesDetailsList(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJobWorkOrder/getJobWorkOrderNamesDetailList/${queryParams.jobId}`
  );
}

export function getAimmLiteJobWorkOrderInfo(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJobWorkOrder/getAimmLiteJobWorkOrderInfo/${queryParams.workOrderId}`
  );
}
export function getCompanyIncomeSourceInHouseEmployeeInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseEmployee/getCompanyIncomeSourceInHouseEmployeeInfo/${queryParams.id}`
  );
}
export function getCompanyIncomeSourceSubInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceSub/getCompanyIncomeSourceSubInfo/${queryParams.id}`
  );
}
export function getCompanyOverheadGeneralOfficeInfo(queryParams) {
  return get(
    `${queryParams.companyId}/companyOverheadGeneralOffice/getCompanyOverheadGeneralOfficeInfo/${queryParams.companyOverheadGeneralOfficeId}`
  );
}

// PUT
export function updateAimmLiteJobStatus(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteJob/updateAimmLiteJobStatus`, obj);
}
export function UpdateAimmLiteCustomerStatus(queryParams, obj) {
  return put(`${queryParams.companyId}/customer/UpdateAimmLiteCustomerStatus`, obj);
}
export function updateJobWorkOrder(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteJobWorkOrder`, obj);
}
export function updateJobGPMD(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteJob`, obj);
}
export function updateAddressLite(queryParams, obj) {
  return put(`${queryParams.companyId}/address/updateAddressLite`, obj);
}
export function updateContactLite(queryParams, obj) {
  return put(`${queryParams.companyId}/contact/updateContactLite`, obj);
}
export function updateCustomerLite(queryParams, obj) {
  return put(`${queryParams.companyId}/customer/updateCustomerLite`, obj);
}
export function updateCustomer(queryParams, obj) {
  return put(`${queryParams.companyId}/customer/updateCustomer/${queryParams.customerId}`, obj);
}
export function updateCompanyAdmin(queryParams, obj) {
  return put(`${queryParams.companyId}/companyOverheadAdmin`, obj);
}
export function updateOverheadAdminEmployee(queryParams, obj) {
  return put(`${queryParams.companyId}/companyOverheadAdminEmployee`, obj);
}
export function updateInHouseEmployee(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSourceInHouseEmployee`, obj);
}
export function updateSubcontractor(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSourceSub`, obj);
}
export function updateInHouseCOGs(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSourceInHouseCogs`, obj);
}
export function updateCommissionPercentForCogs(queryParams, obj) {
  return put(
    `${queryParams.companyId}/companyIncomeSource/updateCompanyIncomeSourceCommissionPercentOnly`,
    obj
  );
}
export function updateCompanyInfo(queryParams, obj) {
  return put(`${queryParams.companyId}/company/updateCompanyInfo`, obj);
}
export function updateComMarketing(queryParams, obj) {
  return put(`${queryParams.companyId}/companyOverheadMarketing`, obj);
}
export function updateOverheadGeneralOffice(queryParams, obj) {
  return put(`${queryParams.companyId}/companyOverheadGeneralOffice`, obj);
}
export function updateCompanyInfoPL(queryParams, obj) {
  return put(`${queryParams.companyId}/company/updateCompanyInfoPL`, obj);
}
export function updateCompanyIncomeSourcePL(queryParams, obj) {
  return put(`${queryParams.companyId}/company/updateCompanyIncomeSourcePL`, obj);
}
export function updateComIncomeSource(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSource`, obj);
}
export function updateComIncomeSourceStatus(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSource/updateCompanyIncomeSourceStatus`, obj);
}
export function updateComOverheadTrucksAndEquipment(queryParams, obj) {
  return put(`${queryParams.companyId}/companyOverheadTrucksAndEquipment`, obj);
}

// DEL
export function delJobWorkerOrder(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteJobWorkOrder/${queryParams.workOrderId}`);
}
export function delOverheadAdminEmployee(queryParams) {
  return del(`${queryParams.companyId}/companyOverheadAdminEmployee/${queryParams.id}`);
}
export function delInHouseEmployee(queryParams) {
  return del(`${queryParams.companyId}/companyIncomeSourceInHouseEmployee/${queryParams.id}`);
}
export function delComMarket(queryParams) {
  return del(
    `${queryParams.companyId}/companyOverheadMarketing/${queryParams.companyOverheadMarketingId}`
  );
}
export function delOverheadGeneralOffice(queryParams, obj) {
  return del(
    `${queryParams.companyId}/companyOverheadGeneralOffice/${queryParams.companyOverheadGeneralOfficeId}`
  );
}
export function delCompanyIncomeSource(queryParams) {
  return del(
    `${queryParams.companyId}/companyIncomeSource/deleteCompanyIncomeSource/${queryParams.companyIncomeSourceId}`
  );
}
export function delComOverheadTrucksAndEquipment(queryParams) {
  return del(`${queryParams.companyId}/companyOverheadTrucksAndEquipment/${queryParams.id}`);
}

// POST
export function loginWithPassword(queryParams) {
  return post('/token', { ...queryParams, grant_type: 'password' });
}
export function addNewAddress(queryParams, obj) {
  return post(`${queryParams.companyId}/address/new`, obj);
}
export function addJobWorkOrder(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteJobWorkOrder`, obj);
}
export function addJobService(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteJobOld`, obj);
}
export function addNewCustomerContact(queryParams, obj) {
  return post(`${queryParams.companyId}/contact/new`, obj);
}
export function addNewCustomerBusiness(queryParams, obj) {
  return post(`${queryParams.companyId}/business/new`, obj);
}
export function addNewCustomerFinal(queryParams, obj) {
  return post(`${queryParams.companyId}/customer/aimmLiteCustomerNew`, obj);
}
export function createCompanyAdmin(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadAdmin`, obj);
}
export function createOverheadAdminEmployee(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadAdminEmployee`, obj);
}
export function addSubcontractor(queryParams, obj) {
  return post(`${queryParams.companyId}/companyIncomeSourceSub`, obj);
}
export function addInHouseCOGs(queryParams, obj) {
  return post(`${queryParams.companyId}/companyIncomeSourceInHouseCogs`, obj);
}
export function addInHouseEmployee(queryParams, obj) {
  return post(`${queryParams.companyId}/companyIncomeSourceInHouseEmployee`, obj);
}
export function getCustomersGridList(queryParams, obj) {
  return post(`${queryParams.companyId}/customer/getCustomersGridList`, obj);
}
export function addInsurance(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadInsurance`, obj);
}
export function addComMarketing(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadMarketing`, obj);
}
export function addOverheadGeneralOffice(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadGeneralOffice`, obj);
}
export function addOverheadSmallTools(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadSmallTools`, obj);
}
export function addComIncomeSource(queryParams, obj) {
  return post(`${queryParams.companyId}/companyIncomeSource`, obj);
}
export function addComOverheadSummary(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadSummary`, obj);
}
export function addComOverheadTrucksAndEquipment(queryParams, obj) {
  return post(`${queryParams.companyId}/companyOverheadTrucksAndEquipment`, obj);
}

/**
 * Address APIS
 * @param {*} queryParams
 */
export function getAimmLiteJobAddress(queryParams) {
  return get(`${queryParams.companyId}/address/getAimmLiteJobAddress/${queryParams.addressId}`);
}

export function getShortAddress(queryParams) {
  return get(`${queryParams.companyId}/address/getShortAddress/${queryParams.jobId}`);
}

// Timesheets apis
export function getCsvRecordsByDateRange(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteTimeSheetv2/getForCompanyDateRange`);
}

export function updateManyCsvRecords(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteTimeSheetv2/updateMany`, obj);
}

export function addManyCsvRecords(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteTimeSheetv2/addMany`, obj);
}

export function updateCsvRecord(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteTimeSheetv2/${queryParams.timesheetId}`, obj);
}

export function deleteCsvRecord(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteTimeSheetv2/${queryParams.aimmLiteTimeSheetId}`);
}

export function addCsvRecord(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteTimeSheetv2`, obj);
}

// Get all WOS for a company
export function getAllWOSByCompany(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJobWorkOrder/getWorkOrdersForCompany`);
}

// Get all work order details for a company
export function getAllWorkOrderDetailsByCompany(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJobWorkOrder/getWorkOrderDetailsForCompany`);
}
export function getAllWorkOrderDetails(queryParams) {
  return get(`${queryParams.companyId}/workOrder/all`);
}

// Get all JOBS for a company
export function getAllJobsByCompany(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/all`);
}
export function getJobsList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getJobsList`);
}

//timesheet apis for workorder
export function getWorkOrderTimeSheetInfo(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteTimeSheet/getForWorkOrder/${queryParams.workOrderId}`
  );
}

//apis for sign up
export function signupWithAddress(obj) {
  return post('0/address/new', obj);
}

export function signupWithContact(obj) {
  return post('0/contact/new', obj);
}

export function signupWithCompany(obj) {
  return post('0/company', obj);
}

export function signupWithCompanyFeature(obj) {
  return post('0/companyFeature', obj);
}

export function signupNewUser(obj) {
  return post('0/user/new', obj);
}

export function addOrChangePassword(queryParams, obj) {
  return post(`${queryParams.companyId}/user/changePassword`, obj);
}

export function resetPassword(obj) {
  return post(`0/user/resetPassword`, obj);
}

// Dashboard widgets api
export function createNewWidget(queryParams, obj) {
  return post(`${queryParams.companyId}/user/dashboard`, obj);
}

export function deleteWidget(queryParams) {
  return del(`${queryParams.companyId}/user/dashboard/${queryParams.aimmLiteWidgetId}`);
}

export function updateWidget(queryParams, obj) {
  return put(`${queryParams.companyId}/user/dashboard`, obj);
}

export function getWidgetDetail(queryParams) {
  return get(`${queryParams.companyId}/user/dashboard/${queryParams.aimmLiteWidgetId}`);
}

export function getAllWidgets(queryParams) {
  return get(`${queryParams.companyId}/user/dashboard/all/${queryParams.aimmUserId}`);
}

// gp predictor scenario apis

export function getAllScenarios(queryParams) {
  return get(`${queryParams.companyId}/gpScenario/getForUser/${queryParams.aimmUserId}`);
}

export function postNewScenario(queryParams, obj) {
  return post(`${queryParams.companyId}/gpScenario`, obj);
}

export function updateScenario(queryParams, obj) {
  return put(`${queryParams.companyId}/gpScenario`, obj);
}

export function deleteScenario(queryParams) {
  return del(`${queryParams.companyId}/gpScenario/${queryParams.aimmLiteScenarioId}`);
}

// api to get current users of a company
export function getAllCurrentUsers(queryParams) {
  return get(`${queryParams.companyId}/user/all`);
}

// setup GPMD calculator scenariou apis
export function postAimmLiteScenario(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteScenario`, obj);
}

export function deleteAimmLiteScenario(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteScenario/${queryParams.aimmLiteScenarioId}`);
}

export function getAllAimmLiteScenarios(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteScenario/getForUser/${queryParams.aimmUserId}`);
}

export function updateAimmLiteScenario(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteScenario`, obj);
}

//update company info quick start api
export function updateCompanyInfoQuickStart(queryParams, obj) {
  return put(`${queryParams.companyId}/company/UpdateCompanyInfoQuickStart`, obj);
}

//apis for subcontractor
export function getSubContractorsForIncomeSourcedId(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceSub/getCompanyIncomeSourceSubList/${queryParams.incomeSourceId}`
  );
}

export function addNewSubContractor(queryParams, obj) {
  return post(`${queryParams.companyId}/companyIncomeSourceSub`, obj);
}

export function updateSubContractor(queryParams, obj) {
  return put(`${queryParams.companyId}/companyIncomeSourceSub`, obj);
}

export function delSubContractor(queryParams) {
  return del(
    `${queryParams.companyId}/companyIncomeSourceSub/${queryParams.companyIncomeSourceSubId}`
  );
}

//APIs for the aimmLiteEstimates
export function getAllAimmLiteEstimates(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimateOld/aimmLiteEstimatesForCompany`);
}

export function getAllUnsoldAimmLiteEstimates(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimateOld/unsoldEstimateListForCompany`);
}

export function getUnsoldAimmLiteEstimatesForProject(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateOld/unsoldEstimatesForJob/${queryParams.jobId}`
  );
}

export function getAimmLiteEstimatesForProject(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateOld/aimmLiteEstimatesForJob/${queryParams.jobId}`
  );
}

export function getAimmLiteEstimateInfo(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimateOld/${queryParams.aimmLiteEstimateId}`);
}

export function updateAimmLiteEstimateInfo(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteEstimateOld`, obj);
}

export function addAimmLiteEstimateInfo(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteEstimateOld`, obj);
}

export function getCompanyInfoLists(queryParams) {
  return get(`${queryParams.companyId}/company/getCompanyInfoListShort`);
}

export function getWorkGroup(queryParams) {
  return get(`${queryParams.companyId}/WorkGroup/all`);
}

export function addWorkGroup(queryParams, obj) {
  return post(`${queryParams.companyId}/WorkGroup/new`, obj);
}

export function editWorkGroup(obj) {
  return put(`${obj.companyId}/WorkGroup/${obj.moduleCategoryId}`, obj);
}

export function deleteWorkGroup(queryParams) {
  return del(`${queryParams.companyId}/WorkGroup/delete/${queryParams.moduleCategoryId}`);
}

export function changeStatusWorkGroup(queryParams) {
  return put(
    `${queryParams.companyId}/WorkGroup/updateModuleCategoryStatus/${queryParams.activeInactiveStatus}`,
    queryParams
  );
}

export function getWorkTypeByModuleId(queryParams, moduleCategoryId) {
  return get(`${queryParams.companyId}/WorkType/getWorkTypeByModuleId/${moduleCategoryId}`);
}
export function addWorkTypeModule(queryParams, obj) {
  return post(`${queryParams.companyId}/WorkType/new`, obj);
}
export function getWorkTypeAll(queryParams) {
  return get(`${queryParams.companyId}/WorkType/all`);
}
export function editWorkTypeModule(obj) {
  return put(`${obj.companyId}/WorkType/${obj.moduleSubCategoryId}`, obj);
}
export function deleteWorkType(queryParams) {
  return del(`${queryParams.companyId}/WorkType/delete/${queryParams.moduleSubCategoryId}`);
}

export function changeStatusWorkType(queryParams) {
  return put(
    `${queryParams.companyId}/WorkType/updateModuleSubCategoryStatus/${queryParams.activeInactiveStatus}`,
    queryParams
  );
}
//Estimate New API
export function getAllAimmLiteEstimatesV2(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimate/all`);
}
export function getAllArchivedEstimatesV2(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimate/archiveList`);
}
export function addAimmLiteEstimateInfoV2(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteEstimate/new`, obj);
}
export function updateAimmLiteEstimateInfoV2(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteEstimate/${obj.estimateId}`, obj);
}
export function archiveEstimateV2(queryParams) {
  return post(`${queryParams.companyId}/aimmLiteEstimate/archive/${queryParams.estimateId}`);
}
export function unarchiveEstimateV2(queryParams) {
  return post(`${queryParams.companyId}/aimmLiteEstimate/unarchive/${queryParams.estimateId}`);
}
export function getEstimateModuleByEstimateId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/getEstimateModuleByEstimateId/${queryParams.aimmLiteEstimateId}`
  );
}
export function getAimmLiteEstimateInfoV2(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimate/${queryParams.aimmLiteEstimateId}`);
}
//Estimate Notes API's
export function getEstimatesNotes(queryParams) {
  return get(`${queryParams.companyId}/estimateNote/${queryParams.estimateId}`);
}
export function saveEstimateNotes(queryParams, obj) {
  return post(`${queryParams.companyId}/estimateNote`, obj);
}
export function updatedEstimateNotes(queryParams, obj) {
  return put(`${queryParams.companyId}/estimateNote/${obj.estimateNoteId}`, obj);
}
export function deleteEstimateNote(queryParams) {
  return del(`${queryParams.companyId}/estimateNote/${queryParams.estimateNoteId}`);
}
//Estimate Fees API's
export function getEstimatesFees(queryParams) {
  return get(`${queryParams.companyId}/estimateFee/${queryParams.estimateId}`);
}
export function deleteEstimateFee(queryParams) {
  return del(`${queryParams.companyId}/estimateFee/${queryParams.estimateJobFeeId}`);
}
export function saveEstimateFees(queryParams, obj) {
  return post(`${queryParams.companyId}/estimateFee`, obj);
}
export function updatedEstimateFees(queryParams, obj) {
  return put(`${queryParams.companyId}/estimateFee/${obj.estimateJobFeeId}`, obj);
}
export function getEstimatesFeesType(queryParams) {
  return get(`${queryParams.companyId}/aimmliteJobFeeType/all`);
}
export function getEstimateCalculationBasedModule(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/getEstimateCalculationBasedModule/${queryParams.aimmLiteEstimateId}/${queryParams.estimateModuleStatus}`
  );
}
export function deleteAimmLiteEstimateModule(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteEstimateModule/${queryParams.estimateId}/${queryParams.estimateModuleId}`
  );
}
export function getaimmLiteEstimateModule(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/${queryParams.estimateId}/${queryParams.estimateModuleId}`
  );
}
export function aimmLiteEstimateNew(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteEstimateModule/new`, obj);
}
export function aimmLiteEstimateUpdate(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmLiteEstimateModule/${queryParams.estimateModuleId}`,
    obj
  );
}
export function ApplyGPMDToModules(queryParams, obj) {
  return post(
    `${queryParams.companyId}/aimmLiteEstimateModule/ApplyGPMDToModules/${obj.isGpmdChanged}/${queryParams.estimateModuleStatus}`,
    obj
  );
}

//Estimate Adjustment API's
export function getEstimatesAdjustment(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateAdjustment/getAllByEstimateId?estimateId=${queryParams.estimateId}`
  );
}
export function deleteEstimateAdjustment(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteEstimateAdjustment/deleteAdjustment/${queryParams.adjustmentId}`
  );
}
export function saveEstimateAdjustment(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteEstimateAdjustment/new`, obj);
}
export function updatedEstimateAdjustment(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteEstimateAdjustment/${obj.adjustmentId}`, obj);
}
//Estimate Adjustment API's
export function getAimmLiteJobAll(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/all`);
}
export function getAimmLiteJobArchiveList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/archiveList`);
}
export function archiveJob(queryParams) {
  return post(`${queryParams.companyId}/aimmLiteJob/archive/${queryParams.jobId}`);
}
export function unarchiveJob(queryParams) {
  return post(`${queryParams.companyId}/aimmLiteJob/unarchive/${queryParams.jobId}`);
}
export function getEstimateDetailsByJobId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getEstimateByJobId/${queryParams.jobId}`);
}
//
export function getCrewTypeList(queryParams) {
  return get(`${queryParams.companyId}/crewType/crewTypeList`);
}
export function getJobSupervisorList(queryParams) {
  return get(`${queryParams.companyId}/lookup/getAimmliteSupervisor`);
}
export function getEstimatorList(queryParams) {
  return get(`${queryParams.companyId}/user/getEstimators`);
}
export function getAllSalesRep(queryParams) {
  return get(`${queryParams.companyId}/salesRep/allSalesRep`);
}
export function estimateMarkAsSold(queryParams) {
  return post(
    `${queryParams.companyId}/aimmLiteEstimate/markassold/${queryParams.aimmLiteEstimateId}/${queryParams.jobStatus}`
  );
}
//Work Order Notes
export function getWorkOrderNoteList(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderNote/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function saveWorkOrderNotes(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderNote`, obj);
}
export function updatedWorkOrderNotes(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteWorkOrderNote/${obj.workOrderNoteId}`, obj);
}
export function deleteWorkOrderNotes(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteWorkOrderNote/${queryParams.workOrderNoteId}`);
}
export function cloneEstimateModule(queryParams) {
  return post(
    `${queryParams.companyId}/aimmLiteEstimateModule/clone/${queryParams.estimateId}/${queryParams.estimateModuleId}`
  );
}
// Work Order
export function getAllWorkOrderList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteWorkOrder/getWorkOrderGrid/${queryParams.jobId}`);
}
export function getWorkOrderList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteWorkOrder/getWorkOrdersList`);
}
export function deleteWorkOrder(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteWorkOrder/${queryParams.workOrderId}`);
}
export function deleteWorkOrderNew(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteWorkOrder/delete/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function saveWorkOrderBasicInfo(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrder/new`, obj);
}
export function updatedWorkOrderBasicInfo(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteWorkOrder/${obj.workOrderId}`, obj);
}
export function getWorkOrderDetailsById(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getEstimateModuleByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/getEstimateModuleByWorkOrderId/${queryParams.workOrderId}`
  );
}
export function removeWorkOrderModule(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrder/RemoveModule/${queryParams.estimateModuleId}`
  );
}
export function getManHoursListByWorkOrderId(queryParams) {
  return get(`${queryParams.companyId}/timeSheetEntry/all/${queryParams.workOrderId}`);
}
export function allSalesRep(queryParams) {
  return get(`${queryParams.companyId}/lookup/allEstimateSalesRep`);
}
export function getAllEstimator(queryParams) {
  return get(`${queryParams.companyId}/lookup/allEstimateEstimators`);
}

export function markAsSoldEstimateModule(queryParams) {
  return post(
    `${queryParams.companyId}/aimmLiteEstimateModule/soldEstimateModule/${queryParams.estimateId}/${queryParams.estimateModuleId}`
  );
}
export function isAnyModuleSold(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/isAnyModuleSold/${queryParams.aimmLiteEstimateId}`
  );
}
export function updateJobStatus(queryParams) {
  return post(
    `${queryParams.companyId}/aimmLiteJob/updateJobStatus/${queryParams.jobId}/${queryParams.jobStatus}`
  );
}
export function getWorkOrderScheduleByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderSchedule/getWorkOrderScheduleByWorkOrderId/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getEntriesForWorkOrder(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteTimeSheetV2/entriesForWorkOrder/${queryParams.workOrderId}`
  );
}
export function deleteProjectManDays(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteWorkOrderSchedule/delete/${queryParams.workOrderScheduleId}`
  );
}
export function getWorkOrderMaterialByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderMaterial/getWorkOrderMaterialByWorkOrderId/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}

export function saveWorkOrder(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderMaterial/new/`, obj);
}
export function updateWorkOrder(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrderMaterial/${queryParams.workOrderMaterialId}`,
    obj
  );
}
export function getMaterialById(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderMaterial/${queryParams.workOrderMaterialId}`
  );
}

export function addUpdateProjectedManDays(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSchedule/addworkOrderSchedules`, obj);
}
export function getWorkOrderByJobId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteWorkOrder/getAllWorkOrder/${queryParams.jobId}`);
}
export function movetoWorkOrder(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrderMaterial/MoveToWorkOrder/${queryParams.workOrderMaterialId}/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function deleteMaterial(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteWorkOrderMaterial/Delete/${queryParams.workOrderMaterialId}`
  );
}
export function getPaymentHistoryByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderSubInvoice/getWorkOrderSubInvoiceByWorkOrderId/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function deletePaymentHistory(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteWorkOrderSubInvoice/Delete/${queryParams.workOrderSubInvoiceId}`
  );
}
export function addPaymentHistory(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSubInvoice/new`, obj);
}
export function updatePaymentHistory(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrderSubInvoice/${queryParams.workOrderSubInvoiceId}`,
    obj
  );
}
export function addSubContractor(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSubContractor/new`, obj);
}
export function updateSubContractorInfo(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrderSubContractor/${queryParams.WorkOrderSubcontractorId}`,
    obj
  );
}
export function getSubContractorByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrderSubContractor/getWorkOrderSubContractorByWorkOrderId/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getJobDetailsByJobId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/${queryParams.jobId}`);
}
export function getEstimateCalculationBasedWorkOrder(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getEstimateCalculationBasedWorkOrder/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getProjectedGPMDCalculation(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getProjectedGPMDCalculation/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function updateProjectedGPMD(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrder/ProjectedGPMD/${queryParams.jobId}/${queryParams.workOrderId}?materialCost=${queryParams.materialCost}&APHEPH=${queryParams.aphEph}`
  );
}
export function assignModule(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrder/assignModuleToWorkOrder/${queryParams.jobId}/${queryParams.workOrderId}/${queryParams.moduleId}`
  );
}
export function unAssignModule(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrder/unassignModuleToWorkOrder/${queryParams.jobId}/${queryParams.moduleId}`
  );
}
export function saveSubContractorSchedule(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSchedule/new`, obj);
}
export function updatedSubContractorSchedule(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrderSchedule/${queryParams.workOrderScheduleId}`,
    obj
  );
}
export function deleteSubContractorSchedule(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteWorkOrderSchedule/deletesubschedule/${queryParams.workOrderScheduleId}`
  );
}
export function getUserList(queryParams) {
  return get(`${queryParams.companyId}/lookup/allUsers`);
}
export function getIHMaterialByJobID(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getIHMaterialByJobID/${queryParams.jobId}`);
}
export function getSubCostByJobId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getSubCostByJobId/${queryParams.jobId}`);
}
export function getJobTimesheet(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteTimeSheetv2/getForJob/${queryParams.jobId}`);
}
export function getManHourByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteTimeSheetv2/getForWorkOrder/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function deleteTimeSheet(queryParams) {
  return del(`${queryParams.companyId}/aimmLiteTimeSheetv2/${queryParams.aimmLiteTimeSheetId}`);
}
export function saveTimesheet(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteTimeSheetv2`, obj);
}
export function getTimesheetById(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteTimeSheetv2/${queryParams.aimmLiteTimeSheetId}`);
}
export function updateTimesheet(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteTimeSheetv2/${obj.aimmLiteTimeSheetId}`, obj);
}
export function getWorkOrderBycompanyId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/${queryParams.jobid}`);
}
export function getInHouseHourseByJobId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteTimeSheetv2/getForJob/${queryParams.jobId}`);
}

export function editUpdateJobId(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteJob/${queryParams.jobId}`, obj);
}

export function getActualHourWorkOrder(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteTimeSheetv2/getActualForWorkOrder/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}

export function getJobCordinator(queryParams) {
  return get(`${queryParams.companyId}/lookup/getOverheadAdminEmployeeList`);
}
export function getGpBookingByWorkOrderId(queryParams) {
  return get(
    `${queryParams.companyId}/workOrderGpBookingv2/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getJobEstimated(queryParams) {
  return get(`${queryParams.companyId}/gpmdv2/getEstimatedJobGpmd/${queryParams.jobId}`);
}
export function getJobProjected(queryParams) {
  return get(
    `${queryParams.companyId}/gpmdv2/getProjectedJobGpmd/${queryParams.jobId}/${queryParams.isCloseout}`
  );
}
export function getJobActualData(queryParams) {
  return get(`${queryParams.companyId}/gpmdv2/getActualJobGpmd/${queryParams.jobId}`);
}
export function getEstimatedWorkOrderGpmd(queryParams) {
  return get(
    `${queryParams.companyId}/gpmdv2/getEstimatedWorkOrderGpmd/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getProjectedWorkOrderGpmd(queryParams) {
  return get(
    `${queryParams.companyId}/gpmdv2/getProjectedWorkOrderGpmd/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getActualWorkOrderGpmd(queryParams) {
  return get(
    `${queryParams.companyId}/gpmdv2/getActualWorkOrderGpmd/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}
export function getSchedule(queryParams) {
  return get(`${queryParams.companyId}/scheduling/2020-07-28/2021-07-28`);
}
export function addSchedules(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSchedule/new`, obj);
}

export function getJobAdjustment(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJobAdjustment/getAllByJobId?jobId=${queryParams.jobId}`
  );
}

export function saveJobAdjustment(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteJobAdjustment/new`, obj);
}
export function updatedJobAdjustment(queryParams, obj) {
  return put(`${queryParams.companyId}/aimmLiteJobAdjustment/${obj.jobAdjustmentId}`, obj);
}
export function deleteJobAdjustment(queryParams) {
  return del(
    `${queryParams.companyId}/aimmLiteJobAdjustment/deleteAdjustment/${queryParams.adjustmentId}`
  );
}
export function saveGpBookings(queryParams) {
  return post(`${queryParams.companyId}/workOrderGpBookingv2/SaveGpBookings`, queryParams.data);
}
export function deleteGpBookng(queryParams) {
  return del(`${queryParams.companyId}/workOrderGpBookingv2/${queryParams.workOrderGpBookingId}`);
}
export function getWorkOrderSubSchedule(queryParams) {
  return get(
    `${queryParams.companyId}/aimmliteSubSchedule/getWorkOrderSubSchedule/${queryParams.jobId}/${queryParams.workOrderId}`
  );
}

export function getCrewChiefList(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSourceInHouseEmployee/getCompanyIncomeSourceInHouseCrew`
  );
}

export function addSubContractore(queryParams, obj) {
  return post(
    `${queryParams.companyId}/aimmLiteWorkOrderSubContractor/new/${queryParams.workOrderSubcontractorId}`,
    obj
  );
}
export function addSubDaySchedule(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSubContractor/addSubDaySchedule`, obj);
}
export function updateSubDaySchedule(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSubContractor/updateSubDaySchedule`, obj);
}
export function aimmliteScheduling(queryParams) {
  return get(
    `${queryParams.companyId}/aimmliteScheduling/getInhouseScheduleBoard/` +
      queryParams.startDate +
      '/' +
      queryParams.endDate +
      '/' +
      queryParams.crewId
  );
}
export function aimmLiteJobStatus(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getAll/${queryParams.jobStatus}`);
}
export function aimmLiteCompletedJob(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getCompletedJob`);
}
export function getallStatus(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getAllByStatus/${queryParams.workOrderStatus}`
  );
}
export function getCompletedWorkOrders(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getCompletedWorkOrders/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function getCompletedWorkOrdersbyid(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getCompletingWorkOrderByJob/${queryParams.jobId}`
  );
}
export function addClosejobAmount(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteJob/closeJob`, obj);
}

export function getAllNonCrewWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/nonCrewWeekAtGlance/getAll/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function addNonCrewWeekAtGlance(queryParams, obj) {
  return post(`${queryParams.companyId}/nonCrewWeekAtGlance`, obj);
}
export function updateNonCrewWeekAtGlance(queryParams, obj) {
  return put(`${queryParams.companyId}/nonCrewWeekAtGlance/update`, obj);
}
export function getSubNonSubContractorIncomeSourceList(queryParams) {
  return get(
    `${queryParams.companyId}/companyIncomeSource/getSubNonSubContractorIncomeSourceList/true`
  );
}
export function getAllInHouseWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/weekAtGlance/getInhouse/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function getAllCloseOutWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/weekAtGlance/getCloseOut/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function getAllSubJobsWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/weekAtGlance/getSubJob/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function getAllSummaryWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/weekAtGlance/getSummary/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function getAnalyticsWeekAtGlance(queryParams) {
  return get(
    `${queryParams.companyId}/weekAtGlance/getAnalytics/${queryParams.startDate}/${queryParams.endDate}`
  );
}
export function updateschedual(queryParams, obj) {
  return put(
    `${queryParams.companyId}/aimmliteScheduling/changeSchedule/
  ${queryParams.workOrderScheduleId}`,
    obj
  );
}
export function getAllNonCrewCategoryList(queryParams) {
  return get(`${queryParams.companyId}/NonCrewCategory/all`);
}
export function addNonCrewCategory(queryParams, obj) {
  return post(`${queryParams.companyId}/NonCrewCategory/new`, obj);
}
export function updateNonCrewCategory(queryParams, obj) {
  return put(`${queryParams.companyId}/NonCrewCategory/${queryParams.nonCrewCategoryId}`, obj);
}
export function updateNonCrewCategoryStatus(queryParams) {
  return put(
    `${queryParams.companyId}/NonCrewCategory/updateNonCrewCategoryStatus/${queryParams.nonCrewCategoryId}`
  );
}
export function deleteNonCrewCategory(queryParams) {
  return del(`${queryParams.companyId}/NonCrewCategory/delete/${queryParams.nonCrewCategoryId}`);
}
export function allmodualassign(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimateModule/isAnyModuleNotAssigned/${queryParams.jobId}`
  );
}
export function MarkAsSoldModuleBulk(queryParams) {
  return post(
    `${queryParams.companyId}/aimmLiteEstimateModule/soldEstimateModuleBulk/${queryParams.estimateId}`,
    queryParams.estimateModuleId
  );
}
export function getEstimatedGridList(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteEstimate/allUnsoldEstimates`);
}

export function getCompletedReport(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteReports/getInProgressReport/${queryParams.jobId}`);
}
export function getCompletedJobReport(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteReports/getCompletedJobsReport/${queryParams.jobId}`
  );
}

export function getJobByCustomerId(queryParams) {
  return get(`${queryParams.companyId}/aimmLiteJob/getCustomerJobs/${queryParams.customerId}`);
}
export function getWorkOrderByCustomerId(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteWorkOrder/getCustomerWorkOrders/${queryParams.customerId}`
  );
}
//APIs for the Timesheet Conversion
export function updateDuplicatesList(queryParams, obj) {
  return post(`${queryParams.companyId}/timeSheetConversion/newList`, obj);
}

export function getDuplicatesList(queryParams) {
  return get(`${queryParams.companyId}/timeSheetConversion/all`);
}
export function getWorkorderDropDownData(queryParams) {
  return get(`${queryParams.companyId}/lookup/getWorkOrder/${queryParams.jobSeqNo}`);
}
export function addNewFinalCustomer(queryParams, obj) {
  return post(`${queryParams.companyId}/customer/addCustomer`, obj);
}
export function updateStatus(queryParams) {
  return put(
    `${queryParams.companyId}/aimmLiteWorkOrder/updateStatus/${queryParams.jobSeqNo}/${queryParams.workOrderSeqNo}/${queryParams.status}`
  );
}
export function MoveToProjAndActual(queryParams) {
  return put(
    `${queryParams.companyId}/aimmliteSubSchedule/markIsActual/${queryParams.workOrderScheduleId}/${queryParams.status}`
  );
}
export function archiveCustomer(queryParams) {
  return put(`${queryParams.companyId}/customer/archiveCustomer/${queryParams.customerId}`);
}
export function unarchiveCustomer(queryParams) {
  return put(`${queryParams.companyId}/customer/unarchiveCustomer/${queryParams.customerId}`);
}
export function getJobCloseOutGp(queryParams) {
  return get(`${queryParams.companyId}/gpmdv2/getJobCloseOutGp/${queryParams.jobSeqNo}`);
}
export function cloneSchedule(queryParams, cloneSchedual) {
  return post(`${queryParams.companyId}/aimmLiteWorkOrderSchedule/cloneSchedule`, cloneSchedual);
}
export function addManyEstimateModuleCsvRecords(queryParams, obj) {
  return post(`${queryParams.companyId}/aimmLiteEstimateModule/addmany`, obj);
}
export function getCustomers(queryParams) {
  return get(
    `${queryParams.companyId}/customer/getCustomers?pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&keyword=${queryParams.keyword}&fromDate=${queryParams.fromDate}&toDate=${queryParams.toDate}&sortBy=${queryParams.sortBy}&sortDirection=${queryParams.sortDirection}`
  );
}
export function getEstimateByPageNumber(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteEstimate/getEstimates?status=${queryParams.status}&pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&keyword=${queryParams.keyword}&fromDate=${queryParams.fromDate}&toDate=${queryParams.toDate}&sortBy=${queryParams.sortBy}&sortDirection=${queryParams.sortDirection}`
  );
}
export function getJobByPageNumber(queryParams) {
  return get(
    `${queryParams.companyId}/aimmLiteJob/getJobs/${queryParams.jobStatusid}?pageNumber=${queryParams.pageNumber}&pageSize=${queryParams.pageSize}&keyword=${queryParams.keyword}&fromDate=${queryParams.fromDate}&toDate=${queryParams.toDate}`
  );
}
